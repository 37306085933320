import styles from "./Subject.module.scss";
import styles2 from "../ToFrom/ToFrom.module.scss";

import { useEffect, useRef, useState } from "react";
import { shortId } from "components/tables/EditableTable/utils";
import { forEach } from "assets/functions/ArrayFunctions";

export default function Subject({
    text,
    onSave,
    editable,
    tips,
    setTips,
    reDraw,
    tipsComponent,
}) {
    const [active, setActive] = useState(false);
    const [lastSelection, setLastSelection] = useState();
    const [runSave, setRunSave] = useState(false);
    const [edited, setEdited] = useState(false);

    const [empty, setEmpty] = useState(false);

    const [record, setRecord] = useState([]);

    const black = "#050606";

    function createDisplay(dataCopy, onComplete) {
        if (entry.current) {
            entry.current.innerHTML = "";

            if (dataCopy.length === 1 && !dataCopy[0].text) {
                setEmpty(true);
            } else {
                setEmpty(false);
            }

            for (let div of dataCopy) {
                let realDiv = document.createElement("div");
                realDiv.id = div.id;

                if (div.text) {
                    let words = [];
                    let word = "";
                    let afterSpace = false;
                    let started = false;
                    for (let char of div.text) {
                        if (afterSpace && char !== " ") {
                            words.push(word);
                            afterSpace = false;
                            word = char;
                        } else {
                            word += char;
                            if (char === " ") {
                                if (started) {
                                    afterSpace = true;
                                }
                            } else if (!started) {
                                started = true;
                            }
                        }
                    }
                    words.push(word);

                    let newTips = [];

                    let lineup = [];
                    for (let i = 0; i < words.length; i++) {
                        let obj = { text: words[i] };
                        if (words[i].toLowerCase().trim() === "survey") {
                            const code = "Subject - Contains Survey";
                            let existing = tips.find((t) => t.code === code);
                            if (existing && existing.ignore) {
                                obj.color = black;
                            } else {
                                obj.color = "#ff391f"; // Red
                            }

                            newTips.push({
                                code: code,
                                title: (
                                    <>
                                        {"Subject line contains the word "}
                                        <span
                                            style={{ color: "#ff391f" }}
                                        >{`'${words[i].trim()}'`}</span>
                                        {" in it."}
                                    </>
                                ),
                                ignore: existing ? existing.ignore : false,
                                red: true,
                            });

                            // Add tip
                        } else if (i < 2) {
                            obj.color = black; // Black
                        } else if (i < 4) {
                            const code = "Subject - Little Long";

                            let existing = tips.find((t) => t.code === code);
                            if (existing && existing.ignore) {
                                obj.color = black;
                            } else {
                                obj.color = "#fccb05"; // Yellow
                            }

                            if (!newTips.some((t) => t.code === code)) {
                                newTips.push({
                                    code: code,
                                    title: (
                                        <>
                                            {"Subject line is a "}
                                            <span style={{ color: "#fccb05" }}>
                                                little long
                                            </span>
                                            {". Ideally it is 1-2 words."}
                                        </>
                                    ),
                                    ignore: existing ? existing.ignore : false,
                                });
                            }
                        } else {
                            const code = "Subject - Too Long";
                            let existing = tips.find((t) => t.code === code);
                            if (existing && existing.ignore) {
                                obj.color = black;
                            } else {
                                obj.color = "#ff391f"; // Red
                            }
                            if (!newTips.some((t) => t.code === code)) {
                                newTips.push({
                                    code: code,
                                    title: (
                                        <>
                                            {"Subject line is "}
                                            <span style={{ color: "#ff391f" }}>
                                                too long
                                            </span>
                                            {". Ideally it is 1-2 words."}
                                        </>
                                    ),
                                    ignore: existing ? existing.ignore : false,
                                    red: true,
                                });
                            }
                        }
                        lineup.push(obj);
                    }

                    setTips(newTips);

                    let colors = [];
                    for (let i = 0; i < lineup.length; i++) {
                        if (!i || lineup[i - 1].color !== lineup[i].color) {
                            // starting a new color
                            colors.push({
                                text: lineup[i].text,
                                color: lineup[i].color,
                            });
                        } else {
                            colors[colors.length - 1].text += lineup[i].text;
                        }
                    }

                    for (let c of colors) {
                        let span = document.createElement("span");
                        span.appendChild(document.createTextNode(c.text));
                        span.style.color = c.color;
                        realDiv.appendChild(span);
                    }
                } else {
                    let span = document.createElement("span");
                    span.appendChild(document.createTextNode("\n"));
                    realDiv.appendChild(span);
                }

                // span.appendChild(document.createTextNode(div.text ? div.text : "\n"));
                // realDiv.appendChild(span);

                entry.current.appendChild(realDiv);
            }

            if (onComplete) {
                onComplete();
            }
        }
    }

    const initData = () => {
        let given = text ? text : "";
        if (given) {
            let t = typeof text;
            if (t !== "string") {
                if (t === "number") {
                    given = given.toString();
                } else {
                    given = "";
                }
            }
        }
        let div = {
            id: shortId(),
            text: given,
        };

        return [div];
    };

    const [data, setData] = useState(initData());

    useEffect(() => {
        createDisplay(data);
    }, []);

    useEffect(() => {
        if (runSave) {
            onBlur();
            setRunSave(false);
        }
    }, [runSave]);

    function saveData(copy) {
        let recordCopy = [...record];
        recordCopy.push({ data: data, selection: lastSelection });
        setRecord(recordCopy);
        setData(copy);
        setEdited(true);
    }

    function getDataCopy() {
        let copy = [...data];
        let trueCopy = JSON.parse(JSON.stringify(copy));
        return trueCopy;
    }

    function getText() {
        let text = "";
        for (let i = 0; i < data.length; i++) {
            text += data[i].text;
            if (i < data.length - 1) {
                text += "\n";
            }
        }
        return text;
    }

    function onBlur() {
        if (edited) {
            let text = getText();

            if (onSave) {
                onSave(text);
            }

            setEdited(false);
        }
    }

    function handleChange(e) {
        e.preventDefault();

        if (!lastSelection) {
            return;
        }

        if (lastSelection.isCollapsed) {
            if (
                e.nativeEvent.inputType === "deleteContentBackward" &&
                lastSelection.offset == 0
            ) {
                onDeleteBegginningOfDiv(e);
                return;
            }

            let copy = getDataCopy();
            let index = data.findIndex((d) => d.id === lastSelection.divId);
            let endOffset = lastSelection.offset;

            if (e.nativeEvent.inputType === "insertText") {
                if (endOffset === copy[index].text.length) {
                    copy[index].text += e.nativeEvent.data;
                } else if (!endOffset) {
                    // at the start
                    copy[index].text = e.nativeEvent.data + copy[index].text;
                } else {
                    let first = copy[index].text.slice(0, endOffset);
                    let second = copy[index].text.slice(endOffset);
                    copy[index].text = first + e.nativeEvent.data + second;
                }
                endOffset++;
            } else if (e.nativeEvent.inputType === "deleteContentBackward") {
                if (endOffset === copy[index].text.length) {
                    copy[index].text = copy[index].text.slice(
                        0,
                        copy[index].text.length - 1
                    );
                } else {
                    let first = copy[index].text.slice(0, endOffset - 1);
                    let second = copy[index].text.slice(endOffset);
                    copy[index].text = first + second;
                }

                endOffset--;
            }

            saveData(copy);
            createDisplay(copy, () => {
                let target = document.getElementById(copy[index].id);
                let subCount = 0;
                let count = 0;

                for (let node of target.childNodes) {
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0];
                    if (text) {
                        if (text.nodeValue) {
                            subCount = 0;
                            for (let i = 0; i < text.nodeValue.length; i++) {
                                if (count < endOffset) {
                                    // here
                                    count++;
                                    subCount++;
                                }
                            }

                            if (count == endOffset) {
                                // here
                                target = text;
                                break;
                            }
                        }
                    }
                }

                // if (subCount == 4) debugger;

                const newRange = document.createRange();
                newRange.setStart(target, subCount);
                newRange.collapse(true); // Collapse the range to the start position

                // Remove any existing selections and set the new range
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(newRange);
            });
        } else {
            // Inserting or deleting on a selection
            let isADelete = e.nativeEvent.inputType === "deleteContentBackward";
            let isAnInsert = e.nativeEvent.inputType === "insertText";
            if (isAnInsert || isADelete) {
                let newChar = isAnInsert ? e.nativeEvent.data : "";
                handleSelectionInsert(newChar);
            }
        }
    }

    function onDeleteBegginningOfDiv(e) {
        let copy = getDataCopy();
        // Merge a section back on to another one;
        let index = copy.findIndex((c) => c.id === lastSelection.divId);

        if (!index) {
            // it's at the top
            createDisplayAndDoSelection(data, lastSelection);
            return;
        }

        let selectionToBe = {
            isCollapsed: true,
            divId: copy[index - 1].id,
            offset: copy[index - 1].text.length,
        };

        if (copy[index].text) {
            copy[index - 1].text += copy[index].text;
        }

        copy.splice(index, 1);
        saveData(copy);
        createDisplayAndDoSelection(copy, selectionToBe);
    }

    function handlePaste(e) {
        e.preventDefault();

        if (lastSelection && e.clipboardData.types.includes("text/plain")) {
            let newText = e.clipboardData.getData("text");
            let paragraphs = newText.split("\n");
            if (paragraphs.length > 1) {
                newText = newText.replaceAll("\n", " ");
            }

            if (lastSelection.isCollapsed) {
                let copy = getDataCopy();
                let div = copy.find((d) => d.id === lastSelection.divId);
                // add new text to this div
                let beginning = div.text.substring(0, lastSelection.offset);
                let end = div.text.substring(lastSelection.offset);
                div.text = beginning + newText + end;

                saveData(copy);
                createDisplay(copy, () => {
                    let target = document.getElementById(lastSelection.divId);
                    let count = 0;
                    let targetOffset = 0;
                    for (let node of target.childNodes) {
                        let text =
                            node.nodeName === "#text"
                                ? node
                                : node.childNodes[0];
                        if (text) {
                            let textOffset = 0;
                            if (text.nodeValue) {
                                for (
                                    let i = 0;
                                    i < text.nodeValue.length;
                                    i++
                                ) {
                                    if (
                                        count <
                                        lastSelection.offset + newText.length
                                    ) {
                                        count++;
                                        textOffset++;
                                    }
                                }
                            }
                            if (
                                count ==
                                lastSelection.offset + newText.length
                            ) {
                                target = text;
                                targetOffset = textOffset;
                                break;
                            }
                        }
                    }

                    const newRange = document.createRange();
                    newRange.setStart(target, targetOffset);
                    newRange.collapse(true); // Collapse the range to the start position

                    // Remove any existing selections and set the new range
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(newRange);
                });
            } else {
                handleSelectionInsert(newText);
            }
        }
    }

    function handleSelectionInsert(newText) {
        let copy = getDataCopy();
        let divTrashCan = [];
        let startInd = copy.findIndex(
            (d) => d.id === lastSelection.start.divId
        );
        let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

        for (let i = startInd; i <= endInd; i++) {
            if (i === startInd && i === endInd) {
                let beginning = copy[i].text.substring(
                    0,
                    lastSelection.start.offset
                );
                let end = copy[i].text.substring(lastSelection.end.offset + 1);
                copy[i].text = beginning + end;
                copy[i].text = beginning + newText + end;
                // insert the new text after deleting the old.
            } else if (i == startInd) {
                let beginning = copy[i].text.substring(
                    0,
                    lastSelection.start.offset
                );
                copy[i].text = beginning + newText;

                //add appended end text and styles
                let end = copy[endInd].text.substring(
                    lastSelection.end.offset + 1
                );
                if (end) {
                    copy[i].text += end;
                }
            } else if (startInd < i && i < endInd) {
                divTrashCan.push(i);
            } else if (i == endInd) {
                divTrashCan.push(i);
            }
        }

        if (divTrashCan.length) {
            let keeping = [];
            forEach(copy, (d, i) => {
                if (!divTrashCan.includes(i)) {
                    keeping.push(d);
                }
            });
            copy = keeping;
        }

        saveData(copy);
        createDisplay(copy, () => {
            let target = document.getElementById(lastSelection.start.divId);
            let count = 0;
            let targetOffset = 0;
            for (let node of target.childNodes) {
                let text =
                    node.nodeName === "#text" ? node : node.childNodes[0];
                if (text) {
                    let textOffset = 0;
                    if (text.nodeValue) {
                        for (let i = 0; i < text.nodeValue.length; i++) {
                            if (count < lastSelection.start.offset) {
                                count++;
                                textOffset++;
                            }
                        }
                    }
                    if (count == lastSelection.start.offset) {
                        target = text;
                        targetOffset = textOffset + newText.length;
                        break;
                    }
                }
            }

            const newRange = document.createRange();
            newRange.setStart(target, targetOffset);
            newRange.collapse(true); // Collapse the range to the start position

            // Remove any existing selections and set the new range
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(newRange);
        });
    }

    function handlePasteParagraphs(paragraphs) {
        let copy = getDataCopy();
        if (lastSelection.isCollapsed) {
            let index = copy.findIndex((d) => d.id === lastSelection.divId);
            // break the styles in two.
            let beginning = copy[index].text.substring(0, lastSelection.offset);
            let end = copy[index].text.substring(lastSelection.offset);
            copy[index].text = beginning + paragraphs[0];

            // keep the old styles
            let endFirstPart = paragraphs[paragraphs.length - 1];

            let endDiv = {
                id: shortId(),
                text: endFirstPart + end,
            };
            copy.splice(index + 1, 0, endDiv);

            // Add the middle ones in
            for (let i = 1; i < paragraphs.length - 1; i++) {
                let newDiv = {
                    text: paragraphs[i],
                    id: shortId(),
                };
                copy.splice(index + i, 0, newDiv);
            }

            saveData(copy);
            createDisplay(copy, () => {
                let target = document.getElementById(endDiv.id);
                let count = 0;
                let targetOffset = 0;
                for (let node of target.childNodes) {
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0];
                    if (text) {
                        let textOffset = 0;
                        if (text.nodeValue) {
                            for (let i = 0; i < text.nodeValue.length; i++) {
                                if (count < endFirstPart.length) {
                                    count++;
                                    textOffset++;
                                }
                            }
                        }
                        if (count == endFirstPart.length) {
                            target = text;
                            targetOffset = textOffset;
                            break;
                        }
                    }
                }

                const newRange = document.createRange();
                newRange.setStart(target, targetOffset);
                newRange.collapse(true); // Collapse the range to the start position

                // Remove any existing selections and set the new range
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(newRange);
            });
        } else {
            let divTrashCan = [];
            let startInd = copy.findIndex(
                (d) => d.id === lastSelection.start.divId
            );
            let endInd = copy.findIndex(
                (d) => d.id === lastSelection.end.divId
            );
            let endingId;

            for (let i = startInd; i <= endInd; i++) {
                if (i === startInd && i === endInd) {
                    let beginning = copy[i].text.substring(
                        0,
                        lastSelection.start.offset
                    );
                    let end = copy[i].text.substring(lastSelection.end.offset);

                    let newStartDiv = {
                        text: beginning + paragraphs[0],
                        id: shortId(),
                    };
                    let newEndDiv = {
                        text: paragraphs[paragraphs.length - 1] + end,
                        id: shortId(),
                    };
                    endingId = newEndDiv.id;

                    copy.splice(startInd, 1, newStartDiv);
                    copy.splice(startInd + 1, 0, newEndDiv);
                } else if (i == startInd) {
                    let beginning = copy[i].text.substring(
                        0,
                        lastSelection.start.offset
                    );
                    copy[i].text = beginning + paragraphs[0];
                } else if (startInd < i && i < endInd) {
                    divTrashCan.push(i);
                } else if (i == endInd) {
                    // append the last paragraph onto the end div
                    let end = copy[endInd].text.substring(
                        lastSelection.end.offset + 1
                    );
                    copy[i].text = lastParagraphText + end;
                    endingId = copy[i].id;
                }
            }

            if (divTrashCan.length) {
                let keeping = [];
                forEach(copy, (d, i) => {
                    if (!divTrashCan.includes(i)) {
                        keeping.push(d);
                    }
                });
                copy = keeping;
            }

            // Add the middle ones in
            for (let j = 1; j < paragraphs.length - 1; j++) {
                let newDiv = {
                    text: paragraphs[j],
                    id: shortId(),
                };
                copy.splice(startInd + j, 0, newDiv);
            }

            saveData(copy);
            createDisplay(copy, () => {
                let target = document.getElementById(endingId);
                let count = 0;
                let targetOffset = 0;
                let endOfPaste = paragraphs[paragraphs.length - 1].length;
                for (let node of target.childNodes) {
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0];
                    if (text) {
                        let textOffset = 0;
                        if (text.nodeValue) {
                            for (let i = 0; i < text.nodeValue.length; i++) {
                                if (count < endOfPaste) {
                                    count++;
                                    textOffset++;
                                }
                            }
                        }
                        if (count == endOfPaste) {
                            target = text;
                            targetOffset = textOffset;
                            break;
                        }
                    }
                }

                const newRange = document.createRange();
                newRange.setStart(target, targetOffset);
                newRange.collapse(true); // Collapse the range to the start position

                // Remove any existing selections and set the new range
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(newRange);
            });
        }
    }

    function handleCut(e) {
        if (lastSelection) {
            if (!lastSelection.isCollapsed) {
                if (navigator?.clipboard) {
                    let startInd = data.findIndex(
                        (div) => div.id === lastSelection.start.divId
                    );
                    let endInd = data.findIndex(
                        (div) => div.id === lastSelection.end.divId
                    );
                    let string = "";

                    for (let i = startInd; i <= endInd; i++) {
                        if (i == startInd && i == endInd) {
                            string = data[i].text.slice(
                                lastSelection.start.offset,
                                lastSelection.end.offset + 1
                            );
                        } else if (i == startInd) {
                            string +=
                                data[i].text.slice(lastSelection.start.offset) +
                                "\n";
                        } else if (i > startInd && i < endInd) {
                            string += data[i].text + "\n";
                        } else if (i == endInd) {
                            string += data[i].text.slice(
                                0,
                                lastSelection.end.offset + 1
                            );
                        }
                    }

                    if (document.hasFocus()) {
                        navigator.clipboard.writeText(string);
                        // .then(() => console.log("clipboard cut"));
                    }
                }

                handleSelectionInsert("");
            }
        }
    }

    const container = useRef();
    const entry = useRef();
    const colorRef = useRef();

    function closeEditor(e) {
        if (
            container.current &&
            !container.current.contains(e.target) &&
            !colorRef.current
        ) {
            document.removeEventListener("click", closeEditor, true);
            setActive(false);
        }
    }

    function onSelect(e) {
        if (!active) {
            setActive(true);
            setTimeout(() => {
                // In case there was a select and the cursor ends outside
                document.addEventListener("click", closeEditor, true);
            }, 100);
        }

        const selection = window.getSelection();

        if (!selection.rangeCount) {
            return;
        }
        const range = selection.getRangeAt(0);

        if (range.startContainer == entry.current) {
            // console.log("hit");
            setLastSelection(null);
            return;
        }

        if (selection.isCollapsed) {
            let endOffset = range.endOffset;
            const endContainer = range.endContainer;

            let section = endContainer;

            if (
                endContainer.nodeName === "DIV" &&
                endContainer.parentElement === entry.current &&
                endOffset == 1
            ) {
                // its a blank div
                endOffset = 0;
            } else {
                if (!section.parentElement) {
                    debugger;
                }
                while (section.parentElement !== entry.current) {
                    if (!section.parentElement) {
                        debugger;
                    }
                    section = section.parentElement;
                }

                // count the characters until the actual offset
                for (let node of section.childNodes) {
                    if (node.nodeName === "BR") {
                        continue;
                    }
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0]; // In case the text is outside of the span, which the browser will do on text entry on a blank line/div
                    if (text === endContainer) {
                        break;
                    }
                    if (text.nodeValue) {
                        endOffset += text.nodeValue.length;
                    }
                }
            }

            setLastSelection({
                divId: section.id,
                offset: endOffset,
                isCollapsed: selection.isCollapsed,
            });
        } else {
            // use selection.focusNode && focusOffset to find cursor position

            let start = range.startContainer;
            let startOffset = range.startOffset;

            if (start.nodeName === "#text") {
                while (start.parentElement !== entry.current) {
                    start = start.parentElement;
                }

                for (let node of start.childNodes) {
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0];
                    if (text === range.startContainer) {
                        break;
                    }
                    if (text.nodeValue) {
                        startOffset += text.nodeValue.length;
                    }
                }
            }

            let end = range.endContainer;
            let endOffset = range.endOffset - 1;

            if (end.nodeName === "#text") {
                while (end.parentElement !== entry.current) {
                    end = end.parentElement;
                }

                for (let node of end.childNodes) {
                    let text =
                        node.nodeName === "#text" ? node : node.childNodes[0];
                    if (text === range.endContainer) {
                        break;
                    }
                    if (text.nodeValue) {
                        endOffset += text.nodeValue.length;
                    }
                }
            }

            let focus = null;
            let focusOffset = 0;

            if (selection.focusNode === range.startContainer) {
                focus = start;
                focusOffset = startOffset;
            } else if (selection.focusNode === range.endContainer) {
                focus = end;
                focusOffset = endOffset;
            }

            setLastSelection({
                start: {
                    divId: start.id,
                    offset: startOffset,
                },
                end: {
                    divId: end.id,
                    offset: endOffset,
                },
                focus: {
                    divId: focus?.id,
                    offset: focusOffset,
                },
                isCollapsed: selection.isCollapsed,
            });
        }
    }

    function createDisplayAndDoSelection(dataCopy, selection) {
        createDisplay(dataCopy, () => {
            if (selection) {
                if (selection.isCollapsed) {
                    let target = document.getElementById(selection.divId);
                    let subCount = 0;
                    let count = 0;
                    for (let node of target.childNodes) {
                        let text =
                            node.nodeName === "#text"
                                ? node
                                : node.childNodes[0];
                        if (text) {
                            if (text.nodeValue) {
                                subCount = 0;
                                for (
                                    let i = 0;
                                    i < text.nodeValue.length;
                                    i++
                                ) {
                                    if (count < selection.offset) {
                                        count++;
                                        subCount++;
                                    }
                                }

                                if (count == selection.offset) {
                                    target = text;
                                    break;
                                }
                            }
                        }
                    }

                    const newRange = document.createRange();
                    newRange.setStart(target, subCount);
                    newRange.collapse(true); // Collapse the range to the start position

                    // Remove any existing selections and set the new range
                    const windowSelection = window.getSelection();
                    windowSelection.removeAllRanges();
                    windowSelection.addRange(newRange);
                } else {
                    let startTarget = document.getElementById(
                        selection.start.divId
                    );
                    let subCount = 0;
                    let count = 0;
                    for (let node of startTarget.childNodes) {
                        let text =
                            node.nodeName === "#text"
                                ? node
                                : node.childNodes[0];
                        if (text) {
                            if (text.nodeValue) {
                                subCount = 0;
                                for (
                                    let i = 0;
                                    i < text.nodeValue.length;
                                    i++
                                ) {
                                    if (count < selection.start.offset) {
                                        count++;
                                        subCount++;
                                    }
                                }

                                if (count == selection.start.offset) {
                                    startTarget = text;
                                    break;
                                }
                            }
                        }
                    }

                    let endTarget = document.getElementById(
                        selection.end.divId
                    );
                    let endSubCount = 0;
                    count = 0;
                    for (let node of endTarget.childNodes) {
                        let text =
                            node.nodeName === "#text"
                                ? node
                                : node.childNodes[0];
                        if (text) {
                            if (text.nodeValue) {
                                endSubCount = 0;
                                for (
                                    let i = 0;
                                    i < text.nodeValue.length;
                                    i++
                                ) {
                                    if (count < selection.end.offset + 1) {
                                        count++;
                                        endSubCount++;
                                    }
                                }

                                if (count == selection.end.offset + 1) {
                                    endTarget = text;
                                    break;
                                }
                            }
                        }
                    }

                    const newRange = document.createRange();
                    newRange.setStart(startTarget, subCount);
                    newRange.setEnd(endTarget, endSubCount);

                    // Remove any existing selections and set the new range
                    const windowSelection = window.getSelection();
                    windowSelection.removeAllRanges();
                    windowSelection.addRange(newRange);
                }
            }
        });
    }

    function undo() {
        let recordCopy = [...record];
        let last = recordCopy.pop();
        if (last) {
            if (last.data && last.selection) {
                createDisplayAndDoSelection(last.data, last.selection);
                setData(last.data);
                setEdited(true);
                console.log("here");
                setRunSave(true);
            }
            setRecord(recordCopy);
        }
    }

    function onKeydown(e) {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }

        if (e.ctrlKey || e.metaKey) {
            if (e.key === "z") {
                e.preventDefault();
                undo();
            }
            if (e.key === "b") {
                e.preventDefault();
            }
            if (e.key === "i") {
                e.preventDefault();
            }
            if (e.key === "u") {
                e.preventDefault();
            }
        }

        if (e.key === "Tab" || e.keyCode === 9) {
            e.preventDefault();
        }
    }

    useEffect(() => {
        let val = initData();
        setData(val);
        createDisplay(val);
    }, [text]);

    useEffect(() => {
        let val = initData();
        setData(val);
        createDisplay(val);
        setActive(false);
    }, [editable]);

    useEffect(() => {
        if (reDraw) {
            createDisplay(data);
        }
    }, [reDraw]);

    return (
        <div
            className={`${styles.container}`}
            ref={container}
            onBlur={onBlur}
            // style={{ ...containerStyle }}
        >
            <div className={`${styles.label55}`}>Subject:</div>
            <div
                className={`${styles.entry}`}
                contentEditable={editable}
                onInput={handleChange}
                onSelect={editable ? onSelect : null}
                ref={entry}
                onKeyDown={editable ? onKeydown : null}
                onPaste={editable ? handlePaste : null}
                onCut={editable ? handleCut : null}
                suppressContentEditableWarning={true}
            ></div>

            {tipsComponent ? tipsComponent : <></>}
        </div>
    );
}
