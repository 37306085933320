// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import { AllContacts, Manual, Upload, ImportedCleaner, Audiences, PreviousImports } from "pages";
import { useGetCurrentUser } from "api/resources/organization/users";
import { OrgAudiences } from "pages/contacts/audiences/OrgAudiences";
import { useFetchRole } from "api/resources/organization/roles";
import { useSearchCurrOrgsFields } from "api/resources/organization/organization";
import Importer from "pages/contacts/all_contacts/Importer";
import Audience from "pages/contacts/audiences/Audience";
import UnifinishedUpload from "pages/contacts/all_contacts/NewContacts/upload/UnifinishedUpload";

export const ContactRoutes = ({ refetchTasks }) => {
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  const searchCustomFields = useSearchCurrOrgsFields("");
  return (
    <Routes>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <>
          <Route path="/" element={<Navigate to="all-contacts" />} />
          {getCurrRole.data.role?.canViewOrgContacts &&
            searchCustomFields.isSuccess && (
              <Route
                path="all-contacts/"
                element={
                  <AllContacts
                    user={getCurrUser?.data?.me}
                    organization={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    roles={getCurrRole.data.role}
                    customFields={searchCustomFields.data.fields}
                  />
                }
              />
            )}

          {getCurrRole.data.role.canViewAudiencesTab && (
            <Route
              path="/audiences/*"
              element={
                <Audiences
                  roles={getCurrRole.data.role}
                  user={getCurrUser?.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                />
              }
            />
          )}
          {getCurrRole.data.role.canViewAudiencesTab && (
            <Route
              path="/audiences/:id"
              element={
                <Audience
                  roles={getCurrRole.data.role}
                  user={getCurrUser?.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                />
              }
            />
          )}
         
         
        </>
      )}
    </Routes>
  );
};

export const AudienceRoutes = () => {
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  return (
    <Routes>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <>
          <Route path="/" element={<Navigate to="" />} />
          <Route
            path=":id"
            element={
              <Audience
                roles={getCurrRole.data.role}
                user={getCurrUser?.data?.me}
                organization={getCurrUser.data?.me?.organization?.find(
                  (org) => org.id === getCurrUser.data?.me.organizationId
                )}
              />
            }
          />
        </>
      )}
    </Routes>
  );
};

export const AddContactRoutes = () => {
  // const getCurrUser = useGetCurrentUser();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="updloadcontacts" />} />
      <Route path="manual" element={<Manual />} />
      <Route path="uploadcontacts" element={<Upload />} />
      <Route path=":id" element={<ImportedCleaner />} />
    </Routes>
  );
};
