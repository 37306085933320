import styles from "./CreateSurvey.module.scss";
import Button from "components/Button/Button";
import { useFetchSurveyTagsGql } from "api/resources/organization/surveytags";
import { useEffect, useState } from "react";
import {
  useCopyProject,
  useCreateProjectGql,
  useCreateSurveyFromTemplate,
} from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import { useFetchSurveyTemplates } from "api/resources/organization/organization";
import { ErrorPage } from "pages/error_page/ErrorPage";
import Survey from "components/Survey/Survey";
import { Label } from "components/layouts/Label/Label";
import { SelectField, TextFieldSimple } from "components/inputs";
import {
  frequencies,
  ReactionStandardSurveySettings,
  sendinternvals,
} from "assets/functions/Variables";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import InteractiveSurveysTable from "components/tables/BasicTable/InteractiveSurveysTable";
import Modal from "../../../components/ReactModal/ReactModal.jsx";

export const SurveyTypes = ["Custom", "NPS", "CAHPS", "Brand Equity"];

export const ConactTypes = [
  "Customer",
  "Patient",
  "Employee",
  "Market",
  "Other",
];

export function CreateSurvey({ close, org, show }) {
  const [format, setFormat] = useState("");
  const [template, setTemplate] = useState("");
  const [preview, setPreview] = useState("");
  const [pickedTags, setPickedTags] = useState([]);
  const [type, setType] = useState("Custom");
  const [who, setWho] = useState("Customer");
  const [surveyName, setSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [validName, setValidName] = useState(true);
  const [creating, setCreating] = useState(false);
  const [load, setLoad] = useState(false);
  const [mode, setMode] = useState("main");
  const [page, setPage] = useState(0);
  const [freq, setFreq] = useState("project");
  const [surveyInterval, setInterval] = useState("quarterly");
  const fetchTemplates = useFetchSurveyTemplates();
  const [projectType, setProjectType] = useState("");

  const createFromTemplate = useCreateSurveyFromTemplate();
  const createFromCopy = useCopyProject();
  const createProject = useCreateProjectGql();

  function changeTags(options) {
    setPickedTags(options);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 100);
  }, [template]);

  function changeName(val) {
    if (val && !validName) {
      setValidName(true);
    }
    setSurveyName(val);
  }

  function startPostProcess() {
    if (!creating) {
      // double clicks check
      if (!surveyName) {
        setValidName(false);
      } else {
        let tagArray = pickedTags.length
          ? pickedTags.map((tag) => tag.value)
          : undefined;

        if (format === "scratch") {
          const project = {
            name: surveyName,
            scheduledToStartAt: null,
            scheduledToCloseAt: null,
            defaultLocale: "en",
            status: "Draft",
            timezone: null,
            type: type,
            audienceType: who,
            description: surveyDescription,
            responseCount: 0,
            organizationId: org?.id,
            designSettings: JSON.stringify(ReactionStandardSurveySettings),
            tags: tagArray,
            isProgram: freq === "program",
            activeLink: false,
            sendInterval: freq === "program" ? surveyInterval : null,
            projectType: projectType,
          };

          createProject.mutate(
            {
              data: project,
            },
            {
              onSuccess: (data) => {
                onCreated(data?.createProject?.id);
              },
              onError: (data) => {
                console.log("error creating survey");
                setCreating(false);
              },
            }
          );
          setCreating(true);
        } else if (format === "template") {
          createFromTemplate.mutate(
            {
              data: {
                projectId: template?.projectId,
                name: surveyName,
                description: surveyDescription,
                tags: tagArray,
                isProgram: freq === "program",
                activeLink: false,
                sendInterval: template?.sendInterval,
                audienceType: who,
              },
            },
            {
              onSuccess: (data) => {
                onCreated(data?.createSurveyFromTemplate?.id);
              },
            }
          );
          setCreating(true);
        } else if (format === "copy") {
          createFromCopy.mutate(
            {
              data: {
                projectId: template?.id,
                name: surveyName,
                description: surveyDescription,
                tags: tagArray,
                isProgram: freq === "program",
                activeLink: false,
                sendInterval: template?.sendInterval,
                audienceType: who,
              },
            },
            {
              onSuccess: (data) => {
                onCreated(data?.copyProject?.id);
              },
            }
          );
          setCreating(true);
        }
      }
    }
  }

  function closeOut() {
    setFormat("");
    setSurveyDescription("");
    setSurveyName("");
    setPickedTags([]);
    setType("");
    setValidName(true);
    setTemplate("");
    setMode("main");
    setPreview("");
    history.back();
  }

  const surveyTagsQuery = useFetchSurveyTagsGql();

  const navigate = useNavigate();

  function onCreated(projId) {
    navigate("/project/" + projId + "/survey-details");
    localStorage.setItem("activeprojecttab", 0);
  }

  return (
    <>
      {!creating && (
        <div className={styles.page}>
          <div className={styles.navContainer}>
            <div className={styles.header_3}>Create A Project</div>
          </div>
          <div className={styles.content}>
            {page === 0 && (
              <div className={styles.settings}>
                <span
                  className={styles.header_4}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  What kind of project do you want to create?
                </span>
                <FlexRow style={{ flexWrap: "wrap" }}>
                  <div
                    className={`${styles.box} ${
                      projectType === "Email Campaign" && styles.boxfill
                    }`}
                    // onClick={() => {
                    //   setProjectType("Email Campaign");
                    // }}
                  >
                    <Icon
                      iconName={"envelope"}
                      style={{ fontSize: "1.5rem" }}
                      className={styles.icon}
                    ></Icon>
                    <FlexRow
                      style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        position: "relative",
                      }}
                      gap="0"
                    >
                      <h1 style={{ margin: "0" }}>Email Campaign</h1>
                      <div
                        className={styles.section_label6}
                        style={{
                          margin: "0",
                          marginTop: "-.3rem",
                          position: "absolute",
                          bottom: "1.5rem",
                          left: "0",
                        }}
                      >
                        Coming Soon
                      </div>
                    </FlexRow>
                    <Icon
                      iconName={"question-circle"}
                      tooltip={
                        "An email campaign is a coordinated set of email messages that are sent to a specific group of recipients. This campaign might include promoting a product or service, sharing important updates, nurturing relationships with customers, or driving engagement with content."
                      }
                      style={{ fontSize: ".7rem" }}
                      info
                    ></Icon>
                  </div>

                  <div
                    className={`${styles.box} ${
                      projectType === "Survey Program" && styles.boxfill
                    }`}
                    onClick={() => {
                      setProjectType("Survey Program");
                      setFreq("program");
                    }}
                  >
                    <Icon
                      iconName={"calendar3"}
                      style={{ fontSize: "1.5rem" }}
                      className={styles.icon}
                    ></Icon>
                    <FlexRow
                      style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        position: "relative",
                      }}
                      gap="0"
                    >
                      <h1 style={{ margin: "0" }}>Survey Program</h1>
                      {/* <div
                        className={styles.section_label6}
                        style={{
                          margin: "0",
                          marginTop: "-.3rem",
                          position: "absolute",
                          bottom: "1.5rem",
                          left: "0",
                        }}
                      >
                        Coming Soon
                      </div> */}
                    </FlexRow>
                    <Icon
                      iconName={"question-circle"}
                      tooltip={
                        "A survey program is ongoing. This survey will be conducted at regular intervals or continuously to monitor changes, trends, and patterns. Survey programs are often part of a broader strategy to gather insights, improve processes, and inform decision-making on an ongoing basis."
                      }
                      style={{ fontSize: ".7rem" }}
                      info
                    ></Icon>
                  </div>
                  <div
                    className={`${styles.box} ${
                      projectType === "Survey" && styles.boxfill
                    }`}
                    onClick={() => {
                      setProjectType("Survey");
                      setFreq("project");
                    }}
                  >
                    <Icon
                      iconName={"card-checklist"}
                      style={{ fontSize: "1.5rem" }}
                      className={styles.icon}
                    ></Icon>
                    <h1>One-Time Survey</h1>
                    <Icon
                      iconName={"question-circle"}
                      tooltip={
                        "A one-time survey is a singular, isolated effort to gather data. It is typically conducted to address a specific question or issue at a particular point in time. Once the data is collected and analyzed, the project is concluded."
                      }
                      style={{ fontSize: ".7rem" }}
                      info
                    ></Icon>
                  </div>
                </FlexRow>
              </div>
            )}
            {projectType === "Survey" && page === 0 && (
              <div className={styles.text_2} style={{ maxWidth: "750px" }}>
                A one-time survey is a singular, isolated effort to gather data.
                It is typically conducted to address a specific question or
                issue at a particular point in time. Once the data is collected
                and analyzed, the project is concluded. <br /> <br /> Click
                "Next" to continue.
              </div>
            )}

            {(format === "template" || format === "copy") && (
              <div className={styles.settings}>
                {/* {format === "template" && preview && !load && (
                    <div
                      className={styles.settingSection}
                      style={{
                        padding: "0em",
                        width: "100%",
                        height: "fit-content",
                        borderBottom: "none",
                        fontSize: ".8em",
                      }}
                    >
                      <Survey
                        projectId={preview.projectId}
                        preview
                        stats
                        fontSize="12px"
                      ></Survey>
                    </div>
                  )}
                  {format === "template" && !template && !load && (
                    <div className={styles.description2}>
                      Click on a template and its preview will show up here
                    </div>
                  )} */}

                {(format === "copy" || format === "template") && preview && (
                  <Modal
                    rightStyle
                    dark
                    show={
                      (format === "copy" || format === "template") && preview
                    }
                    modalStyle={{ width: "500px", height: "100%" }}
                    onClose={() => setPreview("")}
                  >
                    <div
                      className={styles.settingSection}
                      style={{
                        padding: "0em",
                        width: "100%",
                        maxWidth: "100%",
                        height: "fit-content",
                        borderBottom: "none",
                        fontSize: ".8em",
                      }}
                    >
                      <div className={styles.header_4}>
                        "{preview.name}" Preview
                      </div>
                      <Survey
                        projectId={preview.id}
                        preview
                        stats
                        fontSize="12px"
                      ></Survey>
                    </div>
                  </Modal>
                )}

                {/* {format === "copy" && !template && !load && (
                    <div className={styles.description2}>
                      Click on a survey and its preview will show up here
                    </div>
                  )} */}
              </div>
            )}
            {page === 1 && (
              <div
                className={styles.settings}
                style={{ width: "100%", maxWidth: "1000px" }}
              >
                <div
                  className={styles.header_4}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  How do you want to create your survey?
                </div>

                <FlexRow start style={{ flexWrap: "wrap" }}>
                  <div
                    className={`${styles.box} ${
                      format === "scratch" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "scratch" ? "" : "scratch");
                      setTemplate(null);
                    }}
                  >
                    <Icon
                      className={styles.icon}
                      iconName={"pencil-square"}
                    ></Icon>
                    <h1>Create From Scratch</h1>
                  </div>
                  <div
                    className={`${styles.box} ${
                      format === "copy" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "copy" ? "" : "copy");
                      setTemplate(null);
                    }}
                  >
                    <Icon className={styles.icon} iconName={"layers"}></Icon>
                    <h1>Copy Past Survey</h1>
                  </div>
                  <div
                    className={`${styles.box} ${
                      format === "template" && styles.boxfill
                    }`}
                    onClick={() => {
                      setFormat(format === "template" ? "" : "template");
                      setTemplate(null);
                    }}
                  >
                    <Icon
                      className={styles.icon}
                      iconName={"clipboard2-check"}
                    ></Icon>
                    <h1>Use a Template</h1>
                  </div>
                  {/* <div
                    className={`${styles.box} ${
                      format === "import" && styles.boxfill
                    }`}
                    onClick={() =>
                      setFormat(format === "import" ? "" : "import")
                    }
                  >
                    <Icon
                      className={styles.icon}
                      iconName={"cloud-arrow-up"}
                    ></Icon>
                    <h1>Import Survey</h1>
                  </div> */}
                </FlexRow>

                {format === "template" && (
                  <div className={styles.fromTemplate}>
                    {/* <span className={styles.fromheader}>
                  Choose a template to use
                </span> */}

                    {fetchTemplates.isLoading && <Loading />}
                    {fetchTemplates.isError && (
                      <ErrorPage
                        error={fetchTemplates.error}
                        backup={"fetchTemplates"}
                      />
                    )}
                    <div className={styles.templates}>
                      {fetchTemplates.isSuccess && (
                        <Templates
                          temps={fetchTemplates.data.getSurveyTemplatesByOrg}
                          setSurveyDescription={setSurveyDescription}
                          setSurveyName={setSurveyName}
                          setLoad={setLoad}
                          setTemplate={setTemplate}
                          template={template}
                          setType={setType}
                          preview={preview}
                          setPreview={setPreview}
                        ></Templates>
                      )}
                    </div>
                  </div>
                )}
                {format === "copy" && (
                  <div className={styles.fromTemplate}>
                    {/* <span className={styles.fromheader}>
                  Choose a template to use
                </span> */}

                    <div className={styles.templates}>
                      <SurveysHandler
                        setSurveyDescription={setSurveyDescription}
                        setSurveyName={setSurveyName}
                        setLoad={setLoad}
                        setTemplate={setTemplate}
                        template={template}
                        preview={preview}
                        setPreview={setPreview}
                      ></SurveysHandler>
                    </div>
                  </div>
                )}
                {format === "scratch" && (
                  <div className={styles.description2}>
                    Click "Next" in the bottom right. You can then type in the
                    survey information. <br /> <br /> Then click "Create Survey"
                    to create your survey and to start building your
                    masterpiece.
                  </div>
                )}
              </div>
            )}
            {!creating && page === 2 && (
              <div className={styles.pageCreate}>
                <div className={styles.settingSection}>
                  <div
                    className={styles.header_3}
                    style={{ margin: "0em", padding: "0em" }}
                  >
                    Survey Details
                  </div>
                  <div
                    className={styles.description2}
                    style={{ margin: "0em", padding: "0em" }}
                  >
                    {format === "scratch" &&
                      "You are building this survey from scratch"}
                    {format === "template" &&
                      "This survey is being built from a template"}
                    {format === "copy" &&
                      "This survey is being built from a copy of another survey"}
                  </div>
                  <div className={styles.setting}>
                    <Label
                      tooltipText={
                        "This the name of your survey. You will be able to identify your survey from this name"
                      }
                      labelIcon={<i className="bi-info-circle"></i>}
                    >
                      Survey Name
                    </Label>
                    <TextFieldSimple
                      valid={validName}
                      invalidMessage="Give your survey a name"
                      value={surveyName}
                      placeholder="Give your survey a name..."
                      onChange={changeName}
                      // style={{color: "black"}}
                    ></TextFieldSimple>
                  </div>
                  <div className={styles.setting}>
                    <Label
                      tooltipText={
                        "This a short description of what your survey will entail."
                      }
                      labelIcon={<i className="bi-info-circle"></i>}
                    >
                      Survey Description <span>(optional)</span>
                    </Label>
                    <TextFieldSimple
                      value={surveyDescription}
                      placeholder="Give a description of your survey..."
                      onSave={(value) => setSurveyDescription(value)}
                    ></TextFieldSimple>
                  </div>
                  <div className={styles.setting}>
                    <Label
                      tooltipText={
                        "This tag will enable you to easily analyze the results of the survey. For example, you can trend surveys that have the same survey tag."
                      }
                      labelIcon={<i className="bi-info-circle"></i>}
                    >
                      Survey Tag <span>(optional)</span>
                    </Label>
                    <div style={{ width: "100%" }}>
                      {surveyTagsQuery.isLoading && (
                        <TextFieldSimple
                          placeholder="Type new tag or select..."
                          disable
                        ></TextFieldSimple>
                      )}
                      {surveyTagsQuery.isSuccess && (
                        <SelectField
                          selectMultiple
                          onChange={changeTags}
                          options={surveyTagsQuery?.data?.surveyTagByOrgId.map(
                            (t) => {
                              return { value: t.id, label: t.label };
                            }
                          )}
                          value={pickedTags}
                          placeholder="Type new tag or select..."
                        ></SelectField>
                      )}
                    </div>
                  </div>
                  <div className={styles.setting}>
                    <Label
                      tooltipText={
                        "Depending on what survey type you choose, your results will adjust to give you more relevant breakouts and data."
                      }
                      labelIcon={<i className="bi-info-circle"></i>}
                    >
                      What kind of survey is it?
                    </Label>

                    <div style={{ width: "100%" }}>
                      <SelectField
                        onChange={(chosen) =>
                          setType(chosen ? chosen.value : undefined)
                        }
                        options={SurveyTypes.map((val) => {
                          return { value: val, label: val };
                        })}
                        value={type ? { value: type, label: type } : undefined}
                        isClearable
                        placeholder="Choose a survey type..."
                      ></SelectField>
                    </div>
                  </div>

                  {freq === "program" && (
                    <div className={styles.setting}>
                      <Label
                        tooltipText={
                          "This determines how often you will be sending your survey out. This can be changed later."
                        }
                        labelIcon={<i className="bi-info-circle"></i>}
                      >
                        How frequent will you be sending out this survey?
                      </Label>

                      <div style={{ width: "100%" }}>
                        <SelectField
                          onChange={(chosen) =>
                            setInterval(chosen ? chosen.value : undefined)
                          }
                          options={sendinternvals}
                          value={
                            surveyInterval
                              ? sendinternvals.find(
                                  (s) => s.value === surveyInterval
                                )
                              : undefined
                          }
                          isClearable
                          placeholder="Choose a survey type..."
                        ></SelectField>
                      </div>
                    </div>
                  )}
                  <div className={styles.setting}>
                    <Label
                      tooltipText={
                        "Our suggestions change depending on who you are sending your survey to."
                      }
                      labelIcon={<i className="bi-info-circle"></i>}
                    >
                      Who will you be sending this survey to?
                    </Label>

                    <div style={{ width: "100%" }}>
                      <SelectField
                        onChange={(chosen) =>
                          setWho(chosen ? chosen.value : undefined)
                        }
                        options={ConactTypes.map((val) => {
                          return { value: val, label: val };
                        })}
                        value={who ? { value: who, label: who } : undefined}
                        isClearable
                        placeholder="Choose Who you will send to..."
                      ></SelectField>
                    </div>
                  </div>

                  {/* {freq === "program" && (
                <>
                  <div
                    className={styles.header}
                    style={{ margin: "0em", padding: "0em", marginTop: "2em" }}
                  >
                    Survey Distribution
                  </div>

                  <div
                    className={styles.setting}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "0em 1em",
                    }}
                  >
                    <div
                      className={styles.description}
                      style={{ padding: "0em", margin: "0em" }}
                    >
                      Set a survey Schedule
                    </div>
                    <ToggleSwitch></ToggleSwitch>
                  </div>
                </>
              )} */}
                </div>
              </div>
            )}
          </div>

          {format != "import" && !creating && mode === "main" && (
            <div className={styles.buttons}>
              {page === 0 && (
                <Button link shadow onClick={closeOut}>
                  Cancel
                </Button>
              )}
              {page != 0 && (
                <Button
                  shadow
                  disable={!projectType}
                  onClick={() => setPage((page) => (page -= 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Back
                </Button>
              )}
              {page === 1 && template && (
                <div className={styles.text_1}>
                  Create a copy of "{template.name}"
                </div>
              )}
              {page === 0 && (
                <Button
                  shadow
                  disable={!projectType}
                  onClick={() => setPage((page) => (page += 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Next
                </Button>
              )}
              {page === 1 && (
                <Button
                  shadow
                  disable={
                    (format === "copy" && !template) ||
                    (format === "template" && !template) ||
                    format === ""
                  }
                  onClick={() => setPage((page) => (page += 1))}
                  blue
                  style={{ zIndex: "100" }}
                  width={100}
                >
                  Next
                </Button>
              )}
              {page === 2 && (
                <Button
                  shadow
                  onClick={startPostProcess}
                  disable={
                    format != "" && format != "template" ? false : !template
                  }
                  blue
                  width={150}
                >
                  Create Survey
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {creating && (
        <div className={styles.creating}>
          <Loading></Loading>
          <span>Your survey is being created this might take a minute...</span>
        </div>
      )}

      {/* {!format && <ChooseFormat setFormat={setFormat} onExit={closeOut} />}
      {format && (
        <>
          {!from && <ChooseFrom setFrom={setFrom} onExit={closeOut} />}
          {from && (
            <>
              {from === "scratch" && (
                <CreateFromScratch
                  onExit={closeOut}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  org={org}
                  onCreated={onCreated}
                />
              )}

              {from === "import" && (
                <SurveyImport
                  onExit={closeOut}
                  roles={roles}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  onCreated={onCreated}
                />
              )}

              {from === "template" && (
                <ChooseTemplate
                  onExit={closeOut}
                  back={() => setFrom("")}
                  tags={
                    surveyTagsQuery.isSuccess
                      ? surveyTagsQuery.data.surveyTagByOrgId
                      : []
                  }
                  onCreated={onCreated}
                />
              )}
            </>
          )}
        </>
      )} */}
    </>
  );
}

function Templates({
  temps,
  template,
  setTemplate,
  setPreview,
  preview,
  setSurveyDescription,
  setLoad,
  setSurveyName,
  setType,
}) {
  const [templates, setTemplates] = useState(setTemps(temps));

  function setTemps(initTemps) {
    let finaltemps = [];
    let currCat = "";
    let temparray = [];
    for (let temp of initTemps) {
      if (temp.category != currCat) {
        if (currCat != "") {
          finaltemps.push({ category: currCat, array: temparray });
        }
        currCat = temp.category;
        temparray = [];
      }
      temparray.push(temp);
    }

    if (currCat != "") {
      finaltemps.push({ category: currCat, array: temparray });
    }

    return finaltemps;
  }

  const [showprev, setShowprev] = useState(false);
  return (
    <div className={styles.alltemps}>
      {templates.map((category, i) => (
        <div key={i}>
          <div
            className={styles.header_3}
            style={{ marginTop: i === 0 ? "0em" : "" }}
            key={i}
          >
            {" "}
            {category?.category}
          </div>
          <div className={styles.chooseItemTemplate}>
            {category?.array.map((temp, i) => (
              <div
                className={`${styles.box} ${
                  template?.id === temp?.id && styles.boxfill
                }`}
                style={{
                  height: "280px",
                  width: "250px",
                  gap: "0em",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  textAlign: "start",
                  backgroundColor: "white",
                  position: "relative",
                }}
                key={i}
                onMouseEnter={() => setShowprev(temp?.id)}
                onMouseLeave={() => setShowprev("")}
              >
                <div className={styles.imagecont}>
                  <img
                    src={
                      temp.imageURL
                        ? require(`assets/images/${temp.imageURL}`)
                        : ""
                    }
                    height={120}
                  ></img>
                </div>
                <div className={styles.boxheader}>
                  <h1 style={{ whiteSpace: "wrap" }}>{temp.name}</h1>
                  <span>{temp.description}</span>
                </div>
                {showprev === temp?.id && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      gap: "1rem",
                      width: "100%",
                      left: "0",
                      top: "1rem",
                    }}
                  >
                    <Button
                      shadow
                      blue
                      onClick={() => {
                        setPreview(
                          template === temp
                            ? ""
                            : { ...temp, id: temp.projectId }
                        );
                        setLoad(true);
                      }}
                    >
                      Preview
                    </Button>
                    {template?.id != temp?.id && (
                      <Button
                        shadow
                        blue
                        onClick={() => {
                          setTemplate(template === temp ? "" : temp);

                          setSurveyName(temp.name);
                          setType(temp.type);
                          setSurveyDescription(temp.description);
                        }}
                      >
                        Choose
                      </Button>
                    )}
                    {template?.id === temp?.id && (
                      <Button
                        shadow
                        blue
                        onClick={() => {
                          setTemplate("");
                          setSurveyName("");
                          setType("");
                          setSurveyDescription("");
                        }}
                      >
                        Undo
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function SurveysHandler({
  template,
  setTemplate,
  preview,
  setPreview,
  setSurveyDescription,
  setLoad,
  setSurveyName,
}) {
  return (
    <>
      <InteractiveSurveysTable
        onRowClick={(temp) => {
          setPreview(preview === temp ? "" : temp);
          setLoad(true);
        }}
        widgetType="survey"
        searchPlaceholder={"Search Survey Projects..."}
        hideActionButton
        surveys
        onClickChoose={(temp) => {
          setTemplate(template === temp ? "" : temp);
          setSurveyName(temp.name + " (COPY)");
          setSurveyDescription(temp.description);
          // setLoad(true);
        }}
        chosen={template?.id}
      ></InteractiveSurveysTable>
    </>
  );
}
