import React, { useState, useEffect } from "react";

// Internal
import styles from "./Dash.module.scss";
import {
  useCheckOrgDash,
  useFetchCurrOrgsFields,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { TabPill } from "components/layouts";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./resizeable.css";
import "./react-grid-styles.scss";
import {
  useCreateVisualization,
  useDeleteWidgetCharts,
} from "api/resources/projects/visualizations";
import AddWidget from "./AddWidget";
import {
  useFetchDashById,
  useFetchDashByUserId,
  useFetchMyDashboard,
  useFetchOrgDashboard,
  useUpdateDashboard,
  useUpdateWidget,
} from "api/resources/account/dashboard";
import DashGrid from "./DashGrid";
import { useCreateWidget, useDeleteWidget } from "api/resources/account/widget";
import WidgetSettings, {
  NewWidgetSettings,
} from "./Widgets/WidgetSettings/WidgetSettings";
import { BackToTable, PickDash } from "./PickDash";
import { forEach } from "assets/functions/ArrayFunctions";
import AddChartToDash from "./AddChartToDash";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Modal from "components/ReactModal/ReactModal";
import { DashReport } from "pages/results/Reports/ReportEditor";

export const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const Dash = ({ user, role, collapse }) => {
  const [activeTab, setActiveTab] = useState(
    role.canViewPersonalDashboard
      ? 0
      : role.canViewOrgDashboard
      ? 1
      : role.dashboardId && role.showDash
      ? 4
      : role.canEditOthersDashboards
      ? 3
      : role.canEditRoleDashboards
      ? 5
      : -1
  );
  const [showDashes, setShowDashes] = useState(false);
  const checkOrgDash = useCheckOrgDash();
  const [showTable, setShowTable] = useState(true);

  function getMenuItems() {
    let options = [];
    if (role.canViewPersonalDashboard) {
      options.push({
        id: 0,
        name: "My Dash",
      });
    }
    if (
      checkOrgDash.data.organization.showDash &&
      checkOrgDash.data.organization.dashboard.id &&
      role.canViewOrgDashboard
    ) {
      options.push({
        id: 1,
        name: "Org Dash",
      });
    }
    if (role?.canEditOthersDashboards) {
      options.push({
        id: 2,
        name: "Others'",
      });
    }
    if (role?.dashboardId && role.showDash) {
      options.push({
        id: 4,
        name: `${role.name}'s Dash`,
      });
    }
    if (role?.canEditRoleDashboards) {
      options.push({
        id: 5,
        name: "Other Roles'",
      });
    }

    return options;
  }

  function onSelectDash(id) {
    setActiveTab(id);
    setShowDashes(false);
    if (!showTable) setShowTable(true);
  }

  return (
    <div className={styles.page}>
      {checkOrgDash.isSuccess && (
        <>
          {!activeTab && (
            <MyDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
            />
          )}
          {activeTab == 1 && (
            <OrgDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
            />
          )}
          {activeTab == 2 && (
            <OthersDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              showTable={showTable}
              setShowTable={setShowTable}
            />
          )}
          {activeTab == 4 && (
            <MyRolesDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
            />
          )}
          {activeTab == 5 && (
            <RoleDash
              user={user}
              role={role}
              setShowDashes={setShowDashes}
              collapse={collapse}
              showTable={showTable}
              setShowTable={setShowTable}
            />
          )}
          <Modal
            rightStyle
            show={showDashes}
            dark
            modalStyle={{ height: "100%", width: "20rem" }}
            onClose={() => setShowDashes(false)}
          >
            <div className={styles.changeDash}>
              {getMenuItems().map((item) => (
                <div
                  onClick={() => onSelectDash(item.id)}
                  className={`${styles.menu_item} ${
                    activeTab === item.id ? styles.menu_item_active : ""
                  }`}
                  style={{ padding: ".5rem" }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

function MyDash({ user, role, setShowDashes, collapse }) {
  const getDashboard = useFetchMyDashboard();

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && <div>Error...</div>}
      {getDashboard.isSuccess && (
        // <DashAPIs
        //   dashboard={getDashboard.data.dashboard}
        //   toggle={toggle}
        //   user={user}
        //   refetch={() => getDashboard.refetch()}
        //   canEdit={role.canEditPersonalDashboard}
        //   setShowDashes={setShowDashes}
        // />
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.canEditPersonalDashboard}
          setShowDashes={setShowDashes}
          collapse={collapse}
        />
      )}
    </>
  );
}

function OrgDash({ user, role, setShowDashes, collapse }) {
  const getDashboard = useFetchOrgDashboard();

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && <div>Error...</div>}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.canEditOrgDashboard}
          setShowDashes={setShowDashes}
          collapse={collapse}
        />
        // <DashAPIs
        //   dashboard={getDashboard.data.dashboard}
        //   toggle={toggle}
        //   user={user}
        //   refetch={() => getDashboard.refetch()}
        //   canEdit={role.canEditOrgDashboard}
        //   setShowDashes={setShowDashes}
        //   multiUserDash
        // />
      )}
    </>
  );
}

function OthersDash({
  user,
  role,
  setShowDashes,
  collapse,
  showTable,
  setShowTable,
}) {
  const [otherUser, setOtherUser] = useState(null);

  function onUserSelect(selectedUser) {
    setOtherUser({ ...selectedUser, organizationId: user.organizationId });
    setShowTable(false);
    setShowDashes(false);
  }

  return (
    <>
      {showTable && (
        <PickDash
          onPick={onUserSelect}
          orgId={user.organizationId}
          setShowDashes={setShowDashes}
          users
        />
      )}
      {!showTable && otherUser && (
        <UsersDash
          user={otherUser}
          role={role}
          setShowTable={setShowTable}
          setShowDashes={setShowDashes}
          collapse={collapse}
        />
      )}
    </>
  );
}

function UsersDash({ user, role, setShowTable, setShowDashes, collapse }) {
  const getDashboard = useFetchDashByUserId(user.id);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && <div>Error...</div>}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.canEditOthersDashboards}
          setShowDashes={setShowDashes}
          collapse={collapse}
          setShowTable={setShowTable}
        />
        // <DashAPIs
        //   dashboard={getDashboard.data.dashboard}
        //   toggle={toggle}
        //   user={user}
        //   refetch={() => getDashboard.refetch()}
        //   canEdit={role.canEditOthersDashboards}
        //   showTable={showTable}
        //   setShowDashes={setShowDashes}
        // />
      )}
    </>
  );
}

function MyRolesDash({ role, user, setShowDashes, collapse }) {
  const getDashboard = useFetchDashById(role.dashboardId);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && <div>Error...</div>}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={role.canEditRoleDashboards}
          setShowDashes={setShowDashes}
          collapse={collapse}
        />
        // <DashAPIs
        //   dashboard={getDashboard.data.dashboard}
        //   toggle={toggle}
        //   user={user}
        //   refetch={() => getDashboard.refetch()}
        //   canEdit={role.canEditRoleDashboards}
        //   setShowDashes={setShowDashes}
        // />
      )}
    </>
  );
}

function RoleDash({ user, role, setShowDashes, collapse, showTable, setShowTable }) {
  const [otherRole, setOtherRole] = useState(null);

  function onSelectRole(selectedRole) {
    setOtherRole(selectedRole);
    setShowTable(false);
    setShowDashes(false);
  }

  return (
    <>
      {showTable && (
        <PickDash
          onPick={onSelectRole}
          orgId={user.organizationId}
          setShowDashes={setShowDashes}
          roles
        />
      )}
      {!showTable && otherRole && (
        <ARolesDash
          user={user}
          usersRole={role}
          otherRole={otherRole}
          setShowTable={setShowTable}
          setShowDashes={setShowDashes}
          collapse={collapse}
        />
      )}
    </>
  );
}

function ARolesDash({
  user,
  usersRole,
  otherRole,
  setShowTable,
  setShowDashes,
  collapse,
}) {
  const getDashboard = useFetchDashById(otherRole.dashboardId);

  return (
    <>
      {getDashboard.isLoading && <Loading></Loading>}
      {getDashboard.isError && <div>Error...</div>}
      {getDashboard.isSuccess && (
        <DashReport
          dashboard={getDashboard.data.dashboard}
          user={user}
          canEdit={usersRole.canEditRoleDashboards}
          setShowDashes={setShowDashes}
          setShowTable={setShowTable}
          collapse={collapse}
        />
        // <DashAPIs
        //   dashboard={getDashboard.data.dashboard}
        //   toggle={toggle}
        //   user={user}
        //   refetch={() => getDashboard.refetch()}
        //   canEdit={usersRole.canEditRoleDashboards}
        //   showTable={showTable}
        //   multiUserDash
        //   setShowDashes={setShowDashes}
        // />
      )}
    </>
  );
}

function DashAPIs({
  dashboard,
  toggle,
  user,
  refetch,
  canEdit,
  multiUserDash,
  showTable,
  setShowDashes,
}) {
  const updateDash = useUpdateDashboard();
  const createWidget = useCreateWidget();
  const updateWidget = useUpdateWidget();
  const deleteWidget = useDeleteWidget();
  const createViz = useCreateVisualization();
  const deleteWidgetCharts = useDeleteWidgetCharts();

  function saveLayouts(layouts) {
    updateDash.mutate(
      {
        id: dashboard.id,
        data: {
          layouts: JSON.stringify(layouts),
        },
      },
      {
        onSuccess: (data) => {
          console.log("saved dash");
          refetch();
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  }

  function addWidgetToDB(newLayout, newWidget) {
    for (let l of newLayout.lg) {
      l.isDraggable = undefined;
    }

    createWidget.mutate(
      {
        data: {
          dashboardId: dashboard.id,
          index: parseInt(newWidget.i),
          name: newWidget.name,
          type: newWidget.type,
          settings: newWidget.settings
            ? JSON.stringify(newWidget.settings)
            : "{}",
          // visualizationId: newWidget.vizId,
        },
        layouts: JSON.stringify(newLayout),
      },
      {
        onSuccess: (data) => {
          console.log("new widget saved");
          refetch();
        },
        onError: (data) => {},
      }
    );
  }

  function createNewViz(viz, onFinish) {
    let data = {
      title: viz.title,
      type: viz.type,
      questionIds: viz.question ? viz.question.map((q) => q.id) : null,
      designSettings: JSON.stringify(viz.designSettings),
      pivotString: viz.pivotString,
      filters: viz.filters ? viz.filters : "{}",
      analysisId: null,
      projectIdsArray: JSON.stringify(viz.projectIdsArray),
      tagIdsArray: JSON.stringify(viz.tagIdsArray),
      programIds: viz.programIds ? JSON.stringify(viz.programIds) : null,
      questionTags: viz.questionTags ? JSON.stringify(viz.questionTags) : null,
      comparison: viz.comparison ? JSON.stringify(viz.comparison) : null,
      dataMethod: viz.dataMethod,
    };

    createViz.mutate(
      {
        data: data,
      },
      {
        onSuccess: (data) => {
          console.log("Added Chart", data);
          onFinish(data.createVisualization.id);
        },
        onError: (data) => {},
      }
    );
  }

  function deleteAWidget(widget) {
    deleteWidget.mutate(
      {
        id: widget.id,
      },
      {
        onSuccess: (data) => {
          refetch();
        },
        onError: (data) => {},
      }
    );

    if (widget?.settings?.charts) {
      deleteCharts(widget);
    }
  }

  function deleteCharts(widget) {
    deleteWidgetCharts.mutate(
      {
        chartIds: {
          ids: widget.settings.charts.map((c) => c.id),
        },
      },
      {
        onSuccess: (data) => {},
        onError: (data) => {},
      }
    );
  }

  function handleWidgetUpdate(newWidget) {
    updateWidget.mutate(
      {
        id: newWidget.id,
        data: {
          name: newWidget.name,
          settings: JSON.stringify(newWidget.settings),
        },
      },
      {
        onSuccess: (data) => {
          // console.log("updated Widget");
          refetch();
        },
        onError: (data) => {},
      }
    );
  }

  return (
    <>
      <DashData
        dash={dashboard}
        deleteAWidget={deleteAWidget}
        updateWidget={handleWidgetUpdate}
        addWidgetToDB={addWidgetToDB}
        createNewViz={createNewViz}
        saveLayouts={saveLayouts}
        user={user}
        toggle={toggle}
        canEdit={canEdit}
        multiUserDash={multiUserDash}
        showTable={showTable}
        setShowDashes={setShowDashes}
      />
    </>
  );
}

function DashData({
  dash,
  deleteAWidget,
  updateWidget,
  addWidgetToDB,
  createNewViz,
  saveLayouts,
  user,
  toggle,
  canEdit,
  multiUserDash,
  showTable,
  setShowDashes,
}) {
  const [above, setAbove] = useState();
  const [edit, setEdit] = useState(false);
  const [showAddWidget, setShowAddWidget] = useState();

  const fetchCustomFields = useFetchCurrOrgsFields();

  function initLayouts() {
    if (dash?.layouts && typeof dash.layouts === "string") {
      return JSON.parse(dash.layouts);
    }
    return {};
  }

  const initWidgets = () => {
    if (dash?.widget) {
      forEach(dash.widget, (w) => {
        if (typeof w.settings === "string") {
          w.settings = JSON.parse(w.settings);
        }
      });
      return dash.widget.toSorted((a, b) => a.index - b.index);
    }
    return [];
  };

  const [layouts, setLayouts] = useState(initLayouts());
  const [widgets, setWidgets] = useState(initWidgets());
  const [itemToBeDropped, setItemToBeDropped] = useState(null);
  const [editNewWidg, setEditNewWidg] = useState(null);

  useEffect(() => {
    setWidgets(initWidgets());
  }, [dash?.widget]);

  useEffect(() => {
    setLayouts(initLayouts());
  }, [dash?.layouts]);

  function deleteWidget(widget) {
    let newLayouts = { ...layouts };

    for (let layout in newLayouts) {
      let size = newLayouts[layout];

      for (let i = widget.index + 1; i < size.length; i++) {
        size[i].i = (i - 1).toString();
      }

      size.splice(widget.index, 1);
    }

    let newWidgets = [...widgets];
    let ind = newWidgets.findIndex((w) => w.id === widget.id);
    if (ind >= 0) {
      newWidgets.splice(ind, 1);
    }
    for (let sibling of newWidgets) {
      if (sibling.index > widget.index) {
        sibling.index--;
      }
    }

    setWidgets(newWidgets);
    setLayouts(newLayouts);
    deleteAWidget(widget);
  }

  function handleSettingsSave(widget) {
    updateWidget(widget);
    setAbove(null);
  }

  function onSettingsClick(widget, layout) {
    if (!above) {
      setAbove(
        <WidgetSettings
          onClose={() => setAbove(null)}
          widget={widget}
          layout={layout}
          onSave={handleSettingsSave}
          onDelete={() => {
            deleteWidget(widget);
            setAbove(null);
          }}
        />
      );
    }
  }

  function newChartWidget(newLayouts, viz) {
    setEditNewWidg(false);
    createNewViz(viz, (vizId) => finishAddingChartWidg(vizId, newLayouts));
  }

  function finishAddingChartWidg(vizId, newLayouts) {
    let newWidget = itemToBeDropped;
    newWidget.settings.charts = [{ id: vizId, orgId: user.organizationId }];
    delete newWidget.settings.needViz;
    addWidgetToDB(newLayouts, newWidget);
  }

  function addWidget(newLayouts) {
    if (itemToBeDropped.type === "survey") {
      if (itemToBeDropped.settings?.needViz) {
        setEditNewWidg(
          <AddChartToDash
            onSave={(viz) => newChartWidget(newLayouts, viz)}
            onClose={handleNevermind}
            custom_fields={fetchCustomFields.data.getCurrOrgFields}
          />
        );
      } else {
        setEditNewWidg(
          <NewWidgetSettings
            onClose={handleNevermind}
            onSave={(widget) => {
              setEditNewWidg(false);
              addWidgetToDB(newLayouts, widget);
            }}
            widget={itemToBeDropped}
          />
        );
      }
    } else if (
      itemToBeDropped.type === "reports" ||
      itemToBeDropped.type === "tile"
    ) {
      setEditNewWidg(
        <NewWidgetSettings
          onClose={handleNevermind}
          onSave={(widget) => {
            setEditNewWidg(false);
            addWidgetToDB(newLayouts, widget);
          }}
          widget={itemToBeDropped}
        />
      );
    } else {
      addWidgetToDB(newLayouts, itemToBeDropped);
      setItemToBeDropped(null);
    }
  }

  function handleNevermind() {
    setEditNewWidg(null);
    setItemToBeDropped(null);
    setLayouts(initLayouts());
  }

  return (
    <>
      <div
        className={styles.headerContainer}
        // style={{ backgroundColor: edit ? "#e9e9e9" : "#ffffff" }}
      >
        <div className={styles.welcome}>{`Welcome, ${
          user.prefix ? user.prefix : ""
        } ${user.firstName} ${user.lastName}`}</div>
        {/* {!edit && toggle && <div className={styles.whosDash}>{toggle}</div>} */}
        <div className={styles.topButtons}>
          {canEdit && !edit && (
            <>
              <Button
                onClick={() => setShowAddWidget(true)}
                style={{
                  display: "flex",
                  gap: "7px",
                }}
                shadow
              >
                <Icon iconName={"layout-wtf"} sapphire></Icon>+ Add Widget
              </Button>
              <Button
                onClick={() => setEdit(true)}
                style={{
                  display: "flex",
                  gap: "7px",
                }}
                shadow
              >
                <Icon iconName={"pencil-square"}></Icon>
                <div>Edit Dashboard</div>
              </Button>
              <Button
                onClick={() => setShowDashes(true)}
                style={{
                  display: "flex",
                  gap: "7px",
                }}
                shadow
              >
                <Icon iconName={"arrow-right-square"} info></Icon>
                <div>Change Dashboard</div>
              </Button>
            </>
          )}
          {edit && (
            <>
              <div
                className={`${styles.topBtn} ${styles.greenBtn}`}
                onClick={() => {
                  setEdit(false);
                  saveLayouts(layouts);
                }}
              >
                Save
              </div>

              <div
                className={`${styles.topBtn} ${styles.redBtn}`}
                onClick={() => {
                  setEdit(false);
                  setLayouts(initLayouts());
                }}
              >
                Cancel
              </div>
            </>
          )}
        </div>
      </div>

      <div className={edit ? styles.pageContentEdit : styles.pageContent}>
        <div id="container" style={{ position: "absolute", top: "0" }}>
          {above}
        </div>
        {showTable && !edit && (
          <div className={styles.pickDifferentUser}>
            <BackToTable onClick={showTable} />{" "}
          </div>
        )}

        {fetchCustomFields.isSuccess && (
          <>
            {showAddWidget && (
              <AddWidget
                close={() => setShowAddWidget(false)}
                setToDrop={setItemToBeDropped}
                currIndex={dash.widget.length}
                multiUserDash={multiUserDash}
              ></AddWidget>
            )}
            <div className={styles.gridContainer}>
              <DashGrid
                widgets={widgets}
                layouts={layouts}
                setLayouts={setLayouts}
                setAbove={setAbove}
                itemToBeDropped={itemToBeDropped}
                editing={edit}
                canEdit={canEdit}
                sendBackLayouts={setLayouts}
                addWidget={addWidget}
                plusWidget={() => setShowAddWidget(true)}
                settingsClick={onSettingsClick}
              ></DashGrid>
            </div>
            {editNewWidg && <>{editNewWidg}</>}
          </>
        )}
      </div>
    </>
  );
}
