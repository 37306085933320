/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";

// GRAPHQL API

export const useFetchMyDashboard = () => {
  const query = gql`
    query FetchDashboard {
      dashboard: fetchDashboard {
        id
        reportId
      }
    }
  `;

  return useGqlQuery(["my dash"], query, {});
};

export const useFetchOrgDashboard = () => {
  const query = gql`
    query FetchOrgDash {
      dashboard: fetchOrgDash {
        id
        reportId
      }
    }
  `;

  return useGqlQuery(["org Dashboard"], query, {});
};

export const useFetchDashByUserId = (userId) => {
  const query = gql`
    query {
      dashboard: fetchDashboardByUserId(id: "${userId}") {
        id
        reportId
      }
    }
  `;

  return useGqlQuery(["usersDash " + userId], query, {});
};

export const useFetchDashById = (id) => {
  const query = gql`
    query {
      dashboard: fetchDashboardById(dashId: "${id}") {
        id
        reportId
      }
    }
  `;

  return useGqlQuery(["dashId " + id], query, {});
};

export const useUpdateDashboard = () => {
  const mutation = gql`
    mutation Mutation($data: DashboardInput!, $id: String!) {
      updateDashboard(data: $data, id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateWidget = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: WidgetInput!) {
      updateWidget(id: $id, data: $data)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateWidgetArray = () => {
  const mutation = gql`
    mutation UpdateWidgetArray($jsonWidgets: String!) {
      updateWidgetArray(jsonWidgets: $jsonWidgets)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useGetCopyToDashInfo = () => {
  const mutation = gql`
    mutation Mutation {
      info: copyToDashInfo {
        mine
        orgs
        myRoles
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCopyVizToDash = () => {
  const mutation = gql`
    mutation Mutation($vizId: String!, $dashId: String!) {
      coppied: copyVizToDash(vizId: $vizId, dashId: $dashId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useCopyVizToDashIds = () => {
  const mutation = gql`
    mutation Mutation($vizId: String!, $dashIds: Ids!) {
      coppied: copyVizToDashIds(vizId: $vizId, dashIds: $dashIds)
    }
  `;

  return useGqlMutation(mutation);
};

export const useCopyVizToUserDashboards = () => {
  const mutation = gql`
    mutation Mutation($vizId: String!, $userIds: Ids!) {
      coppied: copyVizToUserDashboards(vizId: $vizId, userIds: $userIds)
    }
  `;

  return useGqlMutation(mutation);
};
