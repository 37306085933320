import styles from "./PickDash.module.scss";
import InteractiveTable from "components/tables/BasicTable/InteractiveTable";
import {
  useFetchUsersByOrgIdCount,
  useGetCurrentUser,
  useSearchUsersPages,
} from "api/resources/organization/users";
import { useEffect, useState } from "react";
import { useFetchMyOrg } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useFetchOrgRolesList } from "api/resources/organization/roles";
import { useNavigate } from "react-router-dom";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import {
  useCopyVizToDashIds,
  useCopyVizToUserDashboards,
} from "api/resources/account/dashboard";
import { Player } from "@lottiefiles/react-lottie-player";

export function PickDash({ onPick, roles, users, setShowDashes, orgId }) {
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.topButtons}>
          <Button
            onClick={() => setShowDashes(true)}
            style={{
              display: "flex",
              gap: "7px",
            }}
            shadow
          >
            <Icon iconName={"arrow-right-square"} info></Icon>
            <div>Change Dashboard</div>
          </Button>
        </div>
      </div>

      <div className={styles.container}>
        {users && <PickUser onPick={onPick} orgId={orgId} />}
        {roles && <PickRole onPick={onPick} orgId={orgId} />}
      </div>
    </>
  );
}

export function CopyToDashes({ roles, users, onClose, vizId }) {
  const getCurrUser = useGetCurrentUser();
  const copyToDashIds = useCopyVizToDashIds();
  const copyToUsers = useCopyVizToUserDashboards();

  const [chosen, setChosen] = useState([]);
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);

  function onPick(something) {
    let id = roles ? something.dashboardId : something.id;

    let index = chosen.indexOf(id);
    if (index > -1) {
      setChosen([...chosen.slice(0, index), ...chosen.slice(index + 1)]);
    } else {
      setChosen([...chosen, ...[id]]);
    }
  }

  function onCopy() {
    const options = {
      onSuccess: (data) => {
        if (data.coppied) {
          setCoppied(true);
          setTimeout(() => {
            setCoppied(false);
            onClose();
          }, 1500);
        }
        setCopying(false);
      },
      onError: (data) => {
        setCopying(false);
      },
    };

    if (roles) {
      copyToDashIds.mutate(
        {
          vizId: vizId,
          dashIds: {
            ids: chosen,
          },
        },
        options
      );
    }
    if (users) {
      copyToUsers.mutate(
        {
          vizId: vizId,
          userIds: {
            ids: chosen,
          },
        },
        options
      );
    }
    setCopying(true);
  }

  return (
    <Modal
      show
      modalStyle={{ borderRadius: "1rem", padding: "1rem" }}
      onClose={onClose}
    >
      <div className={styles.copyContainer}>
        {getCurrUser.isLoading && <Loading />}
        {getCurrUser.isSuccess && (
          <>
            {users && (
              <PickUser
                onPick={onPick}
                orgId={getCurrUser.data.me.organizationId}
                chosen={chosen}
              />
            )}
            {roles && (
              <PickRole
                onPick={onPick}
                orgId={getCurrUser.data.me.organizationId}
                chosen={chosen}
              />
            )}
          </>
        )}

        <div
          className={styles.buttons}
          style={!chosen.length ? { visibility: "hidden" } : undefined}
        >
          <Button shadow onClick={onClose}>
            <div className={styles.button}>
              <i className="bi-x-lg"></i> Cancel
            </div>
          </Button>
          <Button shadow blue onClick={onCopy}>
            <div className={styles.button}>
              {!coppied && !copying && (
                <>
                  <i className="bi-printer"></i> Copy to Dashboards
                </>
              )}
              {copying && (
                <>
                  <Loading height={20} width={20} /> Copying
                </>
              )}
              {coppied && (
                <>
                  <i className="bi-check2"></i>
                  Success
                </>
              )}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function PickUser({ onPick, orgId, chosen }) {
  const myOrg = useFetchMyOrg();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);

  const [sort, setSort] = useState({
    descend: false,
    item: "firstName",
  });

  const getUsers = useSearchUsersPages(searchString, pageSkip, perPage, sort);

  const getUsersCount = useFetchUsersByOrgIdCount(
    searchString,
    pageSkip,
    perPage,
    sort,
    orgId
  );

  const headers = [
    {
      id: 0,
      name: "First Name",
      accessor: "firstName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      name: "Last Name",
      accessor: "lastName",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      name: "Role",
      // accessor: "role",
      // objectAccessor: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (u) => <div>{getRoles(u)}</div>,
    },
    {
      id: 3,
      name: "",
      enabled: chosen ? true : false,
      sort: false,
      canSort: false,
      cell_style: (u) => (
        <>
          <i
            className={`bi-check-lg ${styles.check}`}
            style={{
              opacity: chosen.includes(u?.id) ? "1" : "0",
            }}
          ></i>
        </>
      ),
    },
  ];

  function getRoles(u) {
    if (u?.roles) {
      let role = u?.roles.find((role) => role?.organizationId === orgId);
      return role?.name;
    }
    return "";
  }

  useEffect(() => {
    getUsers.refetch();
    getUsersCount.refetch();
  }, [pageNumber, sort, perPage]);

  return (
    <>
      {myOrg.isLoading && <Loading />}
      {myOrg.isSuccess && (
        <div className={styles.holder}>
          <InteractiveTable
            headers={headers}
            queryData={getUsers}
            queryDataAccessor={"users"}
            maxDataQuery={getUsersCount}
            maxDataQueryAccessor={"count"}
            currPage={pageNumber}
            setCurrPage={setPageNumber}
            onRowClick={onPick}
            sort={sort}
            setSort={setSort}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
            perPage={perPage}
            setPerPage={setPerpage}
            searchString={searchString}
            setSearchString={setSearchString}
            tableTitle={`${myOrg.data.organization.name}'s Users`}
            searchPlaceholder={"Search Users..."}
            hideActionButton={true}
          ></InteractiveTable>
        </div>
      )}
    </>
  );
}

export function BackToTable({ onClick }) {
  return (
    <>
      <div className={styles.backToTable} onClick={onClick}>
        <div className={styles.tableIcon}>
          <i className="bi bi-list"></i>{" "}
        </div>
        <div className={styles.backArrow}>
          <i className="bi bi-arrow-return-left"></i>
        </div>
        <div className={styles.descriptionAnchor}>
          <div className={styles.description}>Back to Table</div>
        </div>
      </div>
    </>
  );
}

function PickRole({ onPick, orgId, chosen }) {
  const myOrg = useFetchMyOrg();
  const orgRoles = useFetchOrgRolesList(orgId);

  function scanRoles() {
    let keeping = [];
    for (let role of orgRoles.data.roles) {
      if (role?.dashboardId) {
        // Show even if not showing, so that someone can make edits without having it available until their ready
        keeping.push(role);
      }
    }
    return keeping;
  }

  const navigate = useNavigate();
  function manage() {
    localStorage.setItem("activepage", 8);
    localStorage.setItem("activeorgtab", 3);
    navigate("organization/" + orgId + "/dashboards");
  }

  return (
    <>
      {(myOrg.isLoading || orgRoles.isLoading) && <Loading />}
      {myOrg.isSuccess && orgRoles.isSuccess && (
        <div
          className={styles.roleTableHolder}
          style={chosen ? undefined : { width: "50%" }}
        >
          <div className={styles.roleTableTitle}>
            {`${myOrg.data.organization.name}'s Roles`}
          </div>
          <div className={styles.roleHelp}>
            Only showing roles <em>with a dashboard</em>.{" "}
            <span className={styles.manageRoles} onClick={manage}>
              Manage Roles <i className="bi bi-gear"></i>
            </span>
          </div>

          <div className={styles.tableWrapper}>
            <table
              className={styles.roleTable}
              style={chosen ? { width: "50vw" } : undefined}
            >
              <thead className={styles.tableHeader}>
                <tr>
                  <th>Role</th>
                  <th>Description</th>
                  {chosen && <th></th>}
                </tr>
              </thead>
              <tbody>
                {scanRoles().map((r, i) => (
                  <tr
                    key={r.id}
                    className={i % 2 == 0 ? styles.gray : ""}
                    onClick={() => onPick(r)}
                  >
                    <td>{r.name}</td>
                    <td>{r.description}</td>
                    {chosen && (
                      <td>
                        <i
                          className={`bi-check-lg ${styles.check}`}
                          style={{
                            opacity: chosen.includes(r.dashboardId) ? "1" : "0",
                          }}
                        ></i>
                      </td>
                    )}
                  </tr>
                ))}
                {!scanRoles().length && (
                  <tr className={styles.noHover}>
                    <td className={styles.sorry} colSpan={2}>
                      Sorry, no roles with dashboards.{" "}
                      <span
                        className={styles.manageRoles}
                        style={{ fontSize: ".75rem" }}
                        onClick={manage}
                      >
                        Manage Roles <i className="bi bi-gear"></i>
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
