import React, { useState } from "react";
import styles from "./ColumnMapping.module.scss";
import Modal from "components/ReactModal/ReactModal";
import {
  useFetchCurrOrgsFields
} from "api/resources/organization/organization";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";

function ColumnMapping({ data, headers, headerMap, setHeaderMap, orgId }) {
  const [activeEdit, setActiveEdit] = useState();
  //   const fetchCustomFields = useSearchCurrOrgsFieldsNoProps("prefix");
  const fetchCustomFields = useFetchCurrOrgsFields();

  function getFieldOptions() {
    let custom = fetchCustomFields.data?.getCurrOrgFields?.map((f) => {
      return { id: f.id, value: f.name, label: f.displayName };
    });
    return [
      {
        id: "firstName",
        name: "firstName",
        displayName: "First Name",
        label: "First Name",
        value: "firstName",
      },
      {
        id: "lastName",
        name: "lastName",
        displayName: "Last Name",
        label: "Last Name",
        value: "lastName",
      },
      {
        id: "email",
        name: "email",
        displayName: "Email",
        label: "Email",
        value: "email",
      },
      ...custom,
    ];
  }

  return (
    <>
      <Modal
        show={activeEdit}
        onClose={() => setActiveEdit(null)}
        modalStyle={{
          borderRadius: "1em",
          //   height: importExternal ? "95%" : "800px",
          width: "800px",
          height: "fit-content",
          //   minWidth: manualEntry ? "fit-content" : "",
          padding: "1em",
          paddingTop: "1em",
          paddingBottom: "3em",
          overflow: "visible",
          gap: "1em",
        }}
        dark
      >
        {activeEdit && (
          <>
            <div className={styles.header}>
              Column to Map: {activeEdit?.name}
            </div>
            <div className={styles.header2}>
              {" "}
              <Checkbox
                checked={
                  headerMap[
                    activeEdit?.objectAccessor
                      ? activeEdit?.accessor +
                        " " +
                        activeEdit?.objectAccessor?.toLowerCase()
                      : activeEdit?.accessor
                  ]?.new
                }
                onChange={(e) => {
                  let map = { ...headerMap };
                  map[
                    activeEdit?.objectAccessor
                      ? activeEdit?.accessor +
                        " " +
                        activeEdit?.objectAccessor?.toLowerCase()
                      : activeEdit?.accessor
                  ].new = e.target.checked;
                  setHeaderMap(map);
                }}
              ></Checkbox>{" "}
              Create new Reaction Column from "{activeEdit?.name}" column{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={
                  "If toggled on, this column will be created as a Reaction Column. If this column already exists as a Reaction Column, it will not be created"
                }
                style={{ width: "fit-content", padding: "0em", margin: "0em" }}
                tooltipStyle={{ padding: "2em", width: "300px" }}
              ></Label>
            </div>
            {fetchCustomFields.isSuccess && (
         
              <CombinedInput
                options={getFieldOptions()}
                value={
                  headerMap[
                    activeEdit?.objectAccessor
                      ? activeEdit?.name
                      : activeEdit?.accessor
                  ]?.columns
                }
                selectMultiple
                label="Select a Reaction Column"
                onChange={(vals) =>
                  (headerMap[
                    activeEdit?.objectAccessor
                      ? activeEdit?.accessor +
                        " " +
                        activeEdit?.objectAccessor?.toLowerCase()
                      : activeEdit?.accessor
                  ].columns = vals)
                }
                icon="bi-chevron-down"
                pushDropdown
              ></CombinedInput>
           
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button shadow onClick={() => setActiveEdit(null)}>
                Done
              </Button>
            </div>
          </>
        )}
      </Modal>
      <div className={styles.mappingPage}>
        <span>
          Click "Edit <i className="bi-pencil" style={{ fontSize: ".8em" }}></i>
          " next to the desired column you wish to map. Columns not mapped will
          not be imported.
        </span>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className={styles.mheader}>Reaction Column</th>
              <th>File Column</th>
            </tr>
          </thead>
          <tbody>
            {headers?.map((h, i) => (
              <tr key={i}>
                <td>
                  <div className={styles.edit} onClick={() => setActiveEdit(h)}>
                    Edit <i className="bi-pencil"></i>
                  </div>
                </td>
                <Mapper h={h} headerMap={headerMap} />
                <td>{h?.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ColumnMapping;

function Mapper({ h, headerMap }) {

  const mapped =
    headerMap[
      h.objectAccessor ? h?.name : h.accessor
    ]?.columns?.length > 0 ||
    headerMap[
      h.objectAccessor ? h?.name : h.accessor
    ]?.new;

  return (
    <td>
      {!mapped ? (
        <div className={styles.mapper}>
          <div className={styles.not}>Not mapped</div>{" "}
          <Label
            labelIcon={<i className="bi-question-circle"></i>}
            tooltipText={
              'Columns that are not mapped are not imported into Reaction. Click "Edit" to map this field to an existing Reaction Column or choose to create this as a new Reaction Column'
            }
            style={{ width: "fit-content", padding: "0em", margin: "0em" }}
            tooltipStyle={{ padding: "2em", width: "300px" }}
          ></Label>
        </div>
      ) : (
        <>
          {headerMap[
            h.objectAccessor ? h?.name: h.accessor
          ]?.columns?.map((map, i) => (
            <React.Fragment key={i}>
              {map?.label}
              {headerMap[
                h.objectAccessor
                  ? h?.name
                  : h.accessor
              ]?.columns?.length != 1 &&
              i + 1 !=
                headerMap[
                  h.objectAccessor
                    ? h?.name
                    : h.accessor
                ]?.columns?.length
                ? ", "
                : ""}
            </React.Fragment>
          ))}
          {headerMap[
            h.objectAccessor ? h?.name: h.accessor
          ]?.new && (
            <div className={styles.mapper}>
              <div className={styles.new}>{h.name}</div>{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={
                  "On import, this column will be created as a Reaction Column, you will be able to view this column through the site and also use it as a filter."
                }
                style={{ width: "fit-content", padding: "0em", margin: "0em" }}
                tooltipStyle={{ padding: "2em", width: "300px" }}
              ></Label>
            </div>
          )}
        </>
      )}
    </td>
  );
}
