import { useRef, useState, useEffect } from "react";
import styles from "../SettingsAccordion.module.scss";
import Filter from "../Filter/Filter";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { combineFilters, combinedProjIds } from "../../Visualization";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";

//custom accordian
export const FilterModal = ({
    viz,
    close,
    projects,
    custom_fields,
    onFilterChange,
    changeSettingsField,
    externalFilter,
}) => {
    // If there are filters for this viz, use it. If not, use the external filter fields.

    // If a filter appears in the database for a chart, it is individual.

    function getChosenFilter() {
        if (viz.filters) {
            return typeof viz.filters === "string"
                ? JSON.parse(viz.filters)
                : viz.filters;
        }
        return {};
    }

    const [chosenFilters, setChosenFilters] = useState(getChosenFilter());

    const updateChosenFilters = (newFilters) => {
        onFilterChange(newFilters);
        setChosenFilters(newFilters);
    };

    const ref = useRef();

    function clickOut(e) {
        if (ref.current && !ref.current.contains(e.target)) {
            close();
        }
    }

    useEffect(() => {
        document.addEventListener("click", clickOut, true);
        return () => {
            document.removeEventListener("click", clickOut, true);
        };
    }, []);

    return (
        <div className={styles.body} style={{ borderRadius: "1em" }} ref={ref}>
            <div
                key={viz.id}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <div key={"filters"} className={`${styles.headerStatic}`}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1em",
                            padding: "0rem 1rem",
                        }}
                    >
                        Filters
                        <i
                            style={{ fontSize: "1.2em" }}
                            className="bi bi-funnel"
                        ></i>
                    </div>
                </div>
                <span className={styles.xicon} onClick={close}>
                    <i className="bi-x-lg"></i>
                </span>
            </div>
            <UserFieldConstraints
                changeSettingsField={changeSettingsField}
                custom_fields={custom_fields}
                viz={viz}
                changeFilters={updateChosenFilters}
                currentFilter={chosenFilters}
            />

            {chosenFilters && Object.keys(chosenFilters).length > 0 && (
                <div
                    className={styles.setting2}
                    style={{
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        paddingLeft: "1.5em",
                    }}
                >
                    <ToggleSwitch
                        startChecked={viz.designSettings?.showFilterSubtitle}
                        handleCheck={(val) =>
                            changeSettingsField("showFilterSubtitle", val)
                        }
                    ></ToggleSwitch>
                    <Label
                        style={{
                            fontWeight: "500",
                            width: "fit-content",
                            fontSize: ".85em",
                        }}
                    >
                        Show Filters Subtitle
                    </Label>
                </div>
            )}

            <Filter
                custom_fields={custom_fields}
                chosenFilter={chosenFilters}
                updateChosenFilters={updateChosenFilters}
                disabled={
                    viz.designSettings?.dynamic
                        ? viz.designSettings?.userConstraints
                        : null
                }
                projects={projects}
                externalFilter={
                    externalFilter ? JSON.parse(externalFilter) : undefined
                }
            ></Filter>
        </div>
    );
};

//custom accordian
export const FilterAccordian = ({
    viz,
    visible,
    setVisible,
    projects,
    custom_fields,
    onFilterChange,
    changeSettingsField,
    externalFilter,
}) => {
    function getChosenFilter() {
        if (viz.filters) {
            return typeof viz.filters === "string"
                ? JSON.parse(viz.filters)
                : viz.filters;
        }
        return {};
    }

    function getFilterCount() {
        let filtersObject = JSON.parse(combineFilters(viz, externalFilter));
        let count = 0;
        for (let key in filtersObject) {
            let filter = filtersObject[key];
            if (key === "surveys") {
                let allProjIds = combinedProjIds(viz);
                if (allProjIds.length > 1) {
                    for (let survey of filter) {
                        if (allProjIds.includes(survey.id)) {
                            count++;
                        }
                    }
                }
            } else if (key === "answers") {
                count += filter.length;
            } else {
                if (filter?.properties?.length) {
                    count += filter?.properties?.length;
                }
            }
        }
        return count;
    }

    const [chosenFilters, setChosenFilters] = useState(getChosenFilter());

    const updateChosenFilters = (newFilters) => {
        onFilterChange(newFilters);
        setChosenFilters(newFilters);
    };

    return (
        <>
            <div
                key={"filters"}
                className={`${styles.header} ${
                    visible ? styles.headervisible : ""
                }`}
                onClick={!visible ? setVisible : undefined}
            >
                <FlexRow
                    onClick={visible ? setVisible : undefined}
                    style={{ cursor: "pointer" }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1em",
                        }}
                    >
                        Filters{" "}
                        <i
                            style={{ fontSize: "1.2em" }}
                            className="bi bi-funnel"
                        ></i>
                        {getFilterCount() > 0 && (
                            <HorizontalBar
                                rotate
                                height={15}
                                width={2}
                            ></HorizontalBar>
                        )}
                        {getFilterCount() > 0 && (
                            <div className={styles.number}>
                                {getFilterCount()}
                            </div>
                        )}
                    </div>

                    <span className={styles.accordionicon}>
                        <i className="bi bi-caret-left-fill"></i>
                    </span>
                </FlexRow>

                {visible && (
                    <>
                        <UserFieldConstraints
                            changeSettingsField={changeSettingsField}
                            custom_fields={custom_fields}
                            viz={viz}
                            changeFilters={updateChosenFilters}
                            currentFilter={chosenFilters}
                        />

                        {chosenFilters &&
                            Object.keys(chosenFilters).length > 0 && (
                                <div
                                    className={styles.setting2}
                                    style={{
                                        paddingTop: "1em",
                                        paddingBottom: "1em",
                                        paddingLeft: "1.5em",
                                    }}
                                >
                                    <ToggleSwitch
                                        startChecked={
                                            viz.designSettings
                                                ?.showFilterSubtitle
                                        }
                                        handleCheck={(val) =>
                                            changeSettingsField(
                                                "showFilterSubtitle",
                                                val
                                            )
                                        }
                                    ></ToggleSwitch>
                                    <Label
                                        style={{
                                            fontWeight: "500",
                                            width: "fit-content",
                                            fontSize: ".85em",
                                        }}
                                    >
                                        Show Filters Subtitle
                                    </Label>
                                </div>
                            )}

                        <Filter
                            custom_fields={custom_fields}
                            chosenFilter={chosenFilters}
                            updateChosenFilters={updateChosenFilters}
                            disabled={
                                viz.designSettings?.dynamic
                                    ? viz.designSettings?.userConstraints
                                    : null
                            }
                            projects={projects}
                            externalFilter={
                                externalFilter
                                    ? JSON.parse(externalFilter)
                                    : undefined
                            }
                        ></Filter>
                    </>
                )}
            </div>
        </>
    );
};

function UserFieldConstraints({
    changeSettingsField,
    custom_fields,
    viz,
    changeFilters,
    currentFilter,
}) {
    const [showAdd, setShowAdd] = useState(false);

    function toggleByUserFields(val) {
        changeSettingsField("dynamic", val);
        if (val) {
            if (!viz.designSettings?.userConstraints) {
                // to catch old charts
                changeSettingsField("userConstraints", []);
            } else {
                let copy = { ...currentFilter };
                let found = false;
                for (let id of viz.designSettings.userConstraints) {
                    if (currentFilter[id]) {
                        delete copy[id];
                        found = true;
                    }
                }
                if (found) {
                    changeFilters(copy);
                }
            }
        }
    }

    function getWhatFields() {
        let names = [];

        for (let id of viz.designSettings?.userConstraints) {
            let field = custom_fields.find((f) => f.id === id);
            names.push(field);
        }

        return names;
    }

    const ref = useRef();

    function getLeft() {
        let left = [];
        for (let field of custom_fields) {
            if (!viz.designSettings?.userConstraints?.includes(field.id)) {
                left.push(field);
            }
        }
        return left;
    }

    function clickOutsideAdd(e) {
        if ((ref.current && !ref.current?.contains(e.target)) || !ref.current) {
            setShowAdd(false);
            document.removeEventListener("click", clickOutsideAdd, true);
        }
    }

    function openAdd() {
        if (!showAdd) {
            document.addEventListener("click", clickOutsideAdd, true);
            setShowAdd(true);
        }
    }

    function addField(field) {
        let copy = [...viz.designSettings.userConstraints];
        copy.push(field.id);
        changeSettingsField("userConstraints", copy);
        setShowAdd(false);

        if (field.id in currentFilter) {
            let filterCopy = { ...currentFilter };
            delete filterCopy[field.id];
            changeFilters(filterCopy);
        }
    }

    function removeField(field) {
        let copy = [...viz.designSettings.userConstraints];
        let ind = copy.indexOf(field.id);
        if (ind > -1) {
            copy.splice(ind, 1);
            changeSettingsField("userConstraints", copy);
        }
    }

    return (
        <div className={styles.userFieldsContainer}>
            <div className={styles.setting2} style={{ paddingLeft: "1.5em" }}>
                <ToggleSwitch
                    startChecked={viz.designSettings?.dynamic}
                    handleCheck={toggleByUserFields}
                ></ToggleSwitch>
                <Label
                    style={{
                        fontWeight: "500",
                        width: "fit-content",
                        fontSize: ".85em",
                        color: viz.designSettings?.dynamic ? "" : "#a3a4a8",
                    }}
                    iconPosition="left"
                    tooltipText="For each user, only display data from participants whose contact fields match their own"
                    labelIcon={<i className="bi bi-info-square"></i>}
                >
                    Show data according to User's field
                </Label>
            </div>
            {viz.designSettings?.dynamic && (
                <div
                    className={styles.byUserFields}
                    style={{ paddingLeft: "20px" }}
                >
                    <div className={styles.by}>By:</div>

                    <div className={styles.constraints}>
                        {getWhatFields().map((field) => (
                            <div className={styles.constraint}>
                                <div className={styles.constraintName}>
                                    {field.displayName}
                                </div>
                                <div
                                    className={styles.delete}
                                    onClick={() => removeField(field)}
                                >
                                    <i className="bi bi-x"></i>
                                </div>
                            </div>
                        ))}
                        {!showAdd && getLeft().length > 0 && (
                            <div onClick={openAdd} className={styles.addField}>
                                + field
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showAdd && (
                <div className={styles.addConstraints} ref={ref}>
                    {getLeft().map((field) => (
                        <div
                            className={styles.newField}
                            onClick={() => addField(field)}
                        >
                            {field.displayName}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
