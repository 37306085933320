import React, { useState, useEffect } from "react";
import styles from "./ExternalImport.module.scss";
import ContactTable from "../all_contacts/ContactTable";
import salesforce from "assets/images/Salesforce.png";
import axios from "axios";
import { Loading } from "components/Loading/Loading";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";

const initheads = [
  // {
  //   id: "contactName",
  //   name: "Contact Name",
  //   accessor: "firstName",
  //   enabled: true,
  //   sort: false,
  //   canSort: false,
  //   cell_style: (name, id, i, contact) =>
  //     contact && (
  //       <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
  //         {" "}
  //         <div
  //           className={styles.profileImage}
  //           style={{
  //             backgroundColor: contact?.color ? contact?.color : randomColor(),
  //           }}
  //         >
  //           {" "}
  //           <span>
  //             {contact && contact?.firstName[0] + contact?.lastName[0]}
  //           </span>
  //         </div>
  //         {contact && `${contact?.firstName} ${contact?.lastName}`}
  //       </div>
  //     ),
  //   value: "firstName",
  //   label: "Contact Name",
  //   dataType: "text",
  //   enabled: true,
  //   sort: false,
  //   canSort: true,
  //   fullName: true,
  //   custom: false,
  // },
  {
    id: 2,
    name: "Email",
    accessor: "email",
    enabled: true,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "email",
    label: "Email",
    dataType: "text",
    sfName: "Email",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
  {
    id: 2,
    name: "First Name",
    accessor: "firstName",
    enabled: true,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "firstName",
    label: "First Name",
    dataType: "text",
    sfName: "FirstName",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
  {
    id: 2,
    name: "Last Name",
    accessor: "lastName",
    enabled: true,
    // sort: true,
    canSort: false,
    cell_style: null,
    value: "lastName",
    label: "Last Name",
    dataType: "text",
    sfName: "LastName",
    enabled: true,
    sort: false,
    canSort: true,
    fullName: true,
    custom: false,
  },
];



function ExternalImport({
  seeContact,
  organization,
  user,
  type,
  audience,
  customFields,
}) {
  const [headers, setHeaders] = useState(initheads);
  const [externalColumns, setExternalColumns] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importType, setImportType] = useState(false);


  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  useEffect(() => {
    if (type === "Salesforce") {
      let contactColumns = [];

      let url = process.env.REACT_APP_SALESFORCE_COLUMNS;
      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
      };
      let externalCols = [];

      //contact columns
      axios
        .get(url, config)
        .then((response) => {
          if (response?.data && response?.data?.length > 0) {
            for (let head of response.data) {
              let custom = false;
              let og = `${head}`;
              let label = `${head}`;
              if (
                head != "Email" &&
                head != "FirstName" &&
                head != "LastName"
              ) {
                if (head.includes("__c")) {
                  label = head.replace("__c", "");
                  custom = true;
                }
                let name = head.toLowerCase();
                // if (head === "FirstName" || head === "LastName") {
                //   name = head[0].toLowerCase() + head.slice(1);
                // }
                contactColumns.push({
                  id: head,
                  name: label,
                  accessor: head,
                  enabled: true,
                  sfName: og,
                  canSort: false,
                  cell_style: null,
                  value: name,
                  label: label,
                  enabled:
                    custom ||
                    head.toLowerCase().includes("first name") ||
                    head.toLowerCase().includes("last name") ||
                    head.toLowerCase().includes("firstname") ||
                    head.toLowerCase().includes("lastname") ||
                    head.toLowerCase().includes("salutation") ||
                    head.toLowerCase() === "id"
                      ? true
                      : false,
                  sort: false,
                  canSort: true,
                  fullName: true,
                  custom: false,
                  account: false,
                });
              }
            }
            externalCols = response.data;
            getAccountCols(url, config, externalCols, contactColumns);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error?.response?.status === 401) {
            setSessionExpired(true);
            setTimeout(() => {
              handleLoginSalesforce();
            }, 1000);
          }
        });
    }
  }, []);

  function getAccountCols(url, config, externalCols, contactColumns) {
    //account columns
    let accountColumns = [];
    url = process.env.REACT_APP_SALESFORCE_COLUMNS_ACCOUNT;
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data && response?.data?.length > 0) {
          for (let head of response.data) {
            let custom = false;
            let og = `${head}`;
            if (head != "Email") {
              if (head.includes("__c")) {
                head = head.replace("__c", "");
                // custom = true;
              }
              let name = head[0].toLowerCase() + head.slice(1);
              accountColumns.push({
                id: head,
                name: "Account " + head,
                accessor: "account",
                objectAccessor: og,
                enabled: true,
                canSort: false,
                cell_style: null,
                value: "account " + og,
                label: "Account " + head,
                sfName: "Account." + og,
                enabled:
                  custom ? true : false,
                sort: false,
                canSort: true,
                fullName: true,
                custom: false,
                account: true,
              });
            }
          }

          externalCols = [
            ...externalCols,
            // ...["Account.BillingState"]
            ...accountColumns.map((c) => {
              return c?.sfName;
            }),
          ];
          setHeaders([...headers, ...contactColumns, ...accountColumns]);
          setExternalColumns(externalCols);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);
  const [perPage, setPerpage] = useState(40);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");

  function getTitle() {
    return `
    ${organization?.name}
     Contacts`;
  }


  return (
    <>
      {!importing && (
        <div>
          <div className={styles.header}>
            {" "}
            <img src={salesforce} height={40}></img>
            Import contacts from Salesforce{" "}
            {audience && <> to "{audience?.name}"</>}
          </div>
          <div
            className={styles.mainheader}
            // style={{ paddingTop: "0em" }}
          >
            <span className={`${!importType && styles.active}`}>
              Step 1: Choose how to import
            </span>
            <span className={`${importType && styles.active}`}>
              Step 2: Choose who to import
            </span>
            <span>Step 3: Choose where to import with settings</span>
            <span>Step 4: Provide your column mapping</span>
          </div>
          {importType && (
            <div
              className={styles.header}
              style={{
                paddingBottom: "10px",
              }}
            >
              <span>
                {importType === "Select" &&
                  "Choose the contacts and columns you wish to import. Click on a row to select a contact for import."}
                {importType === "Filters" &&
                  "Choose the contacts and columns you wish to import. Choose filters on the left to filter down your groups, if no filters are applied all the contacts will be imported."}
              </span>
            </div>
          )}
          {!importType && (
            <div
              className={styles.header}
              style={{
                padding: "40px 2em",
                paddingBottom: "10px",
                margin: "0px",
                justifyContent: "center",
              }}
            >
              How would you like to import your contacts from salesforce?
            </div>
          )}
        </div>
      )}

      {!importType && externalColumns && (
        <div className={styles.createModal}>
          <div
            className={styles.fromScratch}
            onClick={() => setImportType("Filters")}
          >
            <i className={`bi-people ${styles.icon}`}></i>
            <div>
              Create Groups with filters
              <span>Use filters to separate or join groups of contacts</span>
            </div>
          </div>
          <div
            className={styles.fromScratch}
            onClick={() => setImportType("Select")}
          >
            <i className={`bi-person-plus ${styles.icon}`}></i>
            <div>
              Search and select individuals
              <span>Choose and search your contacts one at a time</span>
            </div>
          </div>
          <div
            className={styles.fromScratch}
            onClick={() => setImportType("Report")}
          >
            <i className={`bi-file-earmark-person ${styles.icon}`}></i>
            <div>
              Import a Salesforce Report
              <span>Import the filters for a previously created report in Salesforce</span>
            </div>
          </div>
        </div>
      )}

      {/* {importing && (
        <div className={styles.header}>
          <div className={styles.goback} onClick={() => setImporting(false)}>
            <div className={styles.backArrow}>
              <i className="bi bi-arrow-left"></i>
            </div>
            Go Back
          </div>
          
          <span>
            When ready, click the "Submit" button to import the chosen contacts
          </span>
        </div>
      )} */}
      {!externalColumns && (
        <div
          className={styles.header}
          style={{
            padding: "0",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
            height: "100%",
          }}
        >
          <Loading></Loading>
          {sessionExpired && (
            <span>
              Your session has expired, rerouting you to Salesforce login...
            </span>
          )}
        </div>
      )}

      {importType && importType === "Report" && (
        <SalesReport
          organizationId={organization?.id}
          setChosenFilters={setChosenFilters}
          setImportType={setImportType}
        ></SalesReport>
      )}
      {externalColumns && importType && importType != "Report" && (
        <ContactTable
          headers={headers}
          setHeaders={setHeaders}
          maxDataQuery={{
            isSuccess: true,
            isLoading: false,
            data: { count: 0 },
          }}
          maxDataQueryAccessor={"count"}
          currPage={pageNumber}
          setCurrPage={setPageNumber}
          maxItems={perPage}
          // maxData={getProjectCount}
          // onRowClick={() => nonting}
          sort={sort}
          setSort={setSort}
          pageSkip={pageSkip}
          setPageSkip={setPageSkip}
          perPage={perPage}
          setPerPage={setPerpage}
          searchString={searchString}
          setSearchString={setSearchString}
          // actionButtonText={"Create/Update Contacts"}
          tableTitle={getTitle()}
          searchPlaceholder={"search contacts..."}
          onClickActionButton={() => setShow(true)}
          hideActionButton={true}
          chosenFilters={chosenFilters}
          setChosenFilters={setChosenFilters}
          orgId={organization?.id}
          external
          user={user}
          externalColumns={externalColumns}
          setExternalImporting={setImporting}
          externalImporting={importing}
          audience={audience}
          audienceId={audience?.id}
          externalType={importType}
          setExternalType={setImportType}
          customFields={customFields}
        ></ContactTable>
      )}
    </>
  );
}

export default ExternalImport;

function SalesReport({ organizationId, setChosenFilters, setImportType }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);
  const [perPage, setPerpage] = useState(40);
  const [searchString, setSearchString] = useState("contacts");
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    let url = "http://localhost:70/sf_reports";
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        offset: pageNumber - 1,
        page_size: perPage,
        search_string: searchString,
        descend: sort?.descend,
      },
    };

    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        console.log(response);
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, [sort, perPage, pageNumber, searchString]);

  function handlerunreport(id) {
    let url = "http://localhost:70/sf_query_report";
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        id: id,
        organizationId: organizationId,
      },
    };

    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        console.log(response);
        setChosenFilters(response?.data);
        setImportType("Filters");
        // setData(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  return (
    <div className={styles.reportpage}>
      <NavTextField
        value={searchString}
        setValue={setSearchString}
        placeholder={"Search Report..."}
      />

      {data?.map((report, i) => (
        <div
          key={i}
          className={styles.report}
          onClick={() => handlerunreport(report?.Id)}
        >
          {report?.Name}
        </div>
      ))}
    </div>
  );
}
