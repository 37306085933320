import { useEffect, useRef, useState } from "react";
import styles from "./ReportWidget.module.scss";
import {
  useCopyReport,
  useDeleteReport,
  useFetchUserReportRole,
} from "api/resources/organization/reports";
import {
  useGetUsersBySharedReport,
  useUpdateUser,
} from "api/resources/organization/users";
import ImageContainer from "components/Image Editor/ImageContainer";
import { trimDate } from "assets/functions/DateFunctions";
import { useQueryClient } from "react-query";
import AddToWidget from "pages/dashboard/AddToDashPopouts/AddToWidget";
import Analysis from "pages/results/Analysis/Analysis";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ToolTip from "components/ToolTip/ToolTip";

function ReportWidget({
  report,
  onClick,
  roles,
  user,
  isOrg,
  refetch,
  organization,
}) {
  const [showAddToDash, setShowAddToDash] = useState(false);
  const [showDots, setShowDots] = useState(false);
  const [analysis] = useState(getAnalysis());
  const [showOrg, setShowOrg] = useState(false);
  const [showShared, setShowShared] = useState(false);
  const [showDraft, setShowDraft] = useState(false);

  function checktype(type) {
    if (report.organizationId) {
      return styles.isclosed;
    }
    if (report.userId && !report.organizationId) {
      return styles.isResults;
    }
    return styles.isReport;
  }

  function checkRole(canEdit) {
    if (canEdit === null) {
      return roles.canCreateEditOrgReports;
    }
    if (canEdit === false) {
      return false;
    }
    if (canEdit === true) {
      return true;
    }
  }

  const [starred, setStarred] = useState(checkStar());
  const [shared, setShared] = useState({
    user: false,
    org: false,
    role: false,
  });

  const role = useFetchUserReportRole(report.id);

  const updateFavorites = useUpdateUser();

  function checkStar() {
    if (user?.favoriteReports) {
      if (user?.favoriteReports.find((id) => id === report.id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function handleUpdateFavs(star) {
    let reports = user?.favoriteReports ? user?.favoriteReports : [];
    if (star) {
      reports.push(report.id);
    } else {
      let index = reports.findIndex((id) => id === report.id);
      reports.splice(index, 1);
    }

    updateFavorites.mutate({
      data: {
        favoriteReports: reports,
      },
    });

    setStarred(!starred);
  }

  function clickShow(e) {
    e.stopPropagation();
    setShowDots(!showDots);
  }

  function addToDash(e) {
    e.stopPropagation();
    setShowAddToDash(true);
    setShowDots(false);
  }

  const queryClient = useQueryClient();
  function onAdded() {
    queryClient.invalidateQueries("report tile exists for " + report.id);
  }

  function closeAddToDash() {
    setShowAddToDash(false);
  }

  function getAnalysis() {
    if (report && report.analysis && report.analysis.length > 0) {
      let order = JSON.parse(report.pageOrder);
      let analysis = undefined;
      if (order && order.length > 0) {
        let anId = order[0];
        analysis = report.analysis.find((a) => a.id === anId);
      }

      return analysis;
    }
  }

  useEffect(() => {
    let sharedWithOrg = false;
    let sharedWithRole = false;
    let sharedWithUser = false;
    for (let share of report?.sharedReport) {
      if (share.organizationId) {
        sharedWithOrg = true;
      }
      if (share.roleId) {
        sharedWithRole = true;
      }
      if (share.userId) {
        sharedWithUser = true;
      }
    }
    setShared({
      user: sharedWithUser,
      org: sharedWithOrg,
      role: sharedWithRole,
    });
  }, []);

  return (
    <>
      <div className={styles.surveyWidget} onClick={() => onClick(report)}>
        <div className={styles.analContainer}>
          {report?.organization &&
            organization.id != report?.organization?.id && (
              <div className={styles.orgName}>
                Origin: <span>{report?.organization?.name}</span>
              </div>
            )}{" "}
          {analysis && (
            <Analysis
              preview
              analysis={analysis}
              // updateCurrReport={updateDraft}
              // draft={draft}
              // addChart={addChart}
              // setAddChart={setAddChart}
              canEdit
              custom_fields={[]}
              externalFilter={[]}
              // updateTracker={updateTracker}
            ></Analysis>
          )}
        </div>
        {/* <div className={styles.reportIcon}>
          {!report?.icon && (
            <span
              className={`bi-bar-chart`}
            ></span>
          )}
          {report?.icon && <span className={`bi bi-${report?.icon}`}></span>}
        </div> */}

        <div className={styles.titleContainer}>
          <div
            className={styles.header_4}
            style={{
              margin: "0",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {report?.name}
          </div>
          {/* <div className={styles.surveyDescription}>{report?.content}</div> */}
        </div>

        <div className={styles.icons}>
          <div className={styles.threeDots} onClick={clickShow}>
            <i className="bi bi-three-dots"></i>
            {role.isSuccess && showDots && (
              <ThreeDots
                report={report}
                refetch={refetch}
                canDelete={checkRole(role.data.canEdit)}
                close={() => setShowDots(false)}
                addToDash={addToDash}
                roles={roles}
                handleUpdateFavs={handleUpdateFavs}
                starred={starred}
              />
            )}
          </div>
        </div>

        {role.isSuccess && (
          <div className={styles.bottomContainer}>
            {shared?.org && (
              <div
                onMouseEnter={() => setShowOrg(true)}
                onMouseLeave={() => setShowOrg(false)}
                style={{ position: "relative" }}
              >
                <i className="bi-buildings"></i>
                <ToolTip show={showOrg}>
                  <div className={styles.orgName2}>
                    Everyone at <span>{report?.organization?.name}</span> can
                    view{" "}
                  </div>
                </ToolTip>
              </div>
            )}
            {shared?.user && (
              <div
                onMouseEnter={() => setShowShared(true)}
                onMouseLeave={() => setShowShared(false)}
                style={{ position: "relative" }}
              >
                <i className="bi-people"></i>
                <ToolTip show={showShared}>
                  <SharedWith report={report} />
                </ToolTip>
              </div>
            )}
            {report.draft && (
              <>
                {/* <div className={styles.draft}>Draft</div> */}
                <div
                  onMouseEnter={() => setShowDraft(true)}
                  onMouseLeave={() => setShowDraft(false)}
                  style={{ position: "relative" }}
                  className={styles.cone}
                >
                  <i className="bi-cone-striped"></i>
                  <ToolTip show={showDraft}>
                    <div className={styles.orgName2}>Draft</div>
                  </ToolTip>
                </div>
              </>
            )}
            <div className={styles.startedText}>
              Modified: {trimDate(report?.updatedAt)}
              {role.isSuccess && (
                <>
                  {report?.user?.firstName && (
                    <span className={styles.owner}>
                      Owner:{" "}
                      <span>
                        {report.userId === user.id
                          ? "(You)"
                          : `${report?.user?.firstName} ${report?.user?.lastName}`}
                      </span>
                    </span>
                  )}
                </>
              )}
            </div>{" "}
            {/* <div>
            <Stat
              title={report?.numPages > 1 ? "Pages" : "Page"}
              value={report?.numPages > 1 ? report?.numPages : 1}
              small
            />
            <Stat
              title={
                report?.numVizualizations > 1
                  ? "Visualizations"
                  : "Visualization"
              }
              value={
                report?.numVizualizations > 1 ? report?.numVizualizations : 1
              }
              small
            />
          </div> */}
          </div>
        )}
      </div>
      {showAddToDash && (
        <AddToWidget
          onFinished={onAdded}
          onClose={closeAddToDash}
          forTile
          tile={{ report: { id: report.id, name: report.name } }}
        />
      )}
    </>
  );
}

export default ReportWidget;

function ThreeDots({
  report,
  refetch,
  close,
  canDelete,
  addToDash,
  roles,
  handleUpdateFavs,
  starred,
}) {
  const ref = useRef();

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      close();
    }
  }

  const deleteReport = useDeleteReport();
  const copyReport = useCopyReport();

  function onDelete(e) {
    e.stopPropagation();
    if (
      window.confirm("Are you sure you'd like to delete " + report?.name + "?")
    ) {
      deleteReport.mutate(
        {
          id: report?.id,
        },
        {
          onSuccess: () => {
            if (refetch) {
              refetch();
            }
          },
          onError: (data) => {},
        }
      );
    }
  }

  function onCopy(e) {
    e.stopPropagation();
    copyReport.mutate(
      {
        id: report.copyId,
        name: report.name + " (copy)",
      },
      {
        onSuccess: (data) => {
          navigate("/report/" + data.copyReport.id);
        },
      }
    );
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  return (
    <div className={styles.settingsBox} ref={ref}>
      <div
        className={styles.menuItem}
        onClick={(e) => {
          e.stopPropagation();
          handleUpdateFavs(!starred);
        }}
      >
        <div className={starred ? styles.starFill : styles.star}>
          {!starred && <i className="bi bi-star"></i>}
          {starred && <i className="bi bi-star-fill"></i>}
        </div>
        {!starred && "Favorite"}
        {starred && "Unfavorite"}
      </div>
      {roles.canViewDashboardTab &&
        (roles.canEditPersonalDashboard ||
          roles.canEditOrgDashboard ||
          roles.canEditRoleDashboards) && (
          <div className={styles.menuItem} onClick={addToDash}>
            <i className="bi bi-speedometer2" style={{ color: "#DF9B6D" }}></i>{" "}
            Add To Dash
          </div>
        )}
      <div className={styles.menuItem}>
        <i className="bi bi-pencil"></i> Edit Report
      </div>
      <div className={styles.menuItem} onClick={(e) => onCopy(e)}>
        <i className="bi bi-layers" style={{ color: "#faca50" }}></i> Create a
        Copy
      </div>
      {canDelete && (
        <div className={styles.menuItem} onClick={(e) => onDelete(e)}>
          <i className="bi bi-trash3" style={{ color: "#FF8878" }}></i> Delete
        </div>
      )}
    </div>
  );
}

function SharedWith({ report }) {
  const users = useGetUsersBySharedReport(report.id);

  return (
    <>
      {users.isSuccess && users.data.response.userCount > 0 && (
        <>
          <div className={styles.shared}>
            Shared With:
            {users.data.response.userCount > 0 && (
              <div className={styles.users}>
                {users.data?.response?.users.map((user, i) => (
                  <ImageContainer
                    key={i}
                    src={user?.image?.imageURL}
                    size={2}
                    margin={i != 0 ? -15 : 0}
                  />
                ))}{" "}
                {users.data.response.userCount > 3 && (
                  <span>
                    + {users.data.response.userCount - 3} other
                    {users.data.response.userCount - 3 > 1 ? "s" : ""}
                  </span>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
