import React, { useState, useRef, useEffect } from "react";
import styles from "./CombinedInput.module.scss";
import {
    findDay,
    findFullMonth,
    findMonth,
} from "assets/functions/DateFunctions";
import Calendar from "react-calendar";
import TimePicker from "react-time-picker/dist/TimePicker";
import { militaryTimeConverter } from "../SelectFieldCalendar/SelectFieldCalendar";

class SelectedDate {
    constructor(year, month, day, time, fullText, timestamp) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.time = time;
        this.fullText = fullText;
        this.timestamp = timestamp;
    }
}

function CombinedInput({
    label,
    value,
    placeholder,
    icon,
    onIconClick,
    onChange,
    select,
    options,
    disable,
    optionHelperText,
    disableInputOnly,
    disableState,
    selectMultiple,
    search,
    style,
    type,
    iconReplacement,
    autoFocus,
    anyWhereOptions,
    canCreate,
    createText,
    inputStyle,
    onBlur,
    editColor,
    nowrap,
    hideChosen,
    nohover,
    searchIcon,
    pushDropdown,
    dropdownonly,
    noSearch,
    inputClass,
    onInputClick,
    chosenStyle,
    shadow,
    date,
    dropStyle,
}) {
    const [val, setVal] = useState(value);
    const [showOptions, setShowOptions] = useState(autoFocus ? true : false);
    const [searchValue, setSearchValue] = useState("");
    const [time, setTime] = useState("10:00");
    const [topOfList, setTopOfList] = useState(
        options && options?.length > 0
            ? options?.length > 100
                ? options?.slice(0, 100)
                : options
            : []
    );

    const [edit, setEdit] = useState(false);
    const [init, setInit] = useState(true);
    const [edited, setEdited] = useState(false);
    const [safeValue, setSafe] = useState(
        value?.label || value?.label === "" ? value?.label : value
    );
    const [initValue] = useState(
        value?.label || value?.label === "" ? value?.label : value
    );

    useEffect(() => {
        if (!disableState) {
            setVal(value);
        }
    }, [value]);

    useEffect(() => {
        if (!disableState) {
            setTopOfList(
                options && options?.length > 0
                    ? options?.length > 100
                        ? options?.slice(0, 100)
                        : options
                    : []
            );
        }
    }, [options]);

    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    useEffect(() => {
        if (onBlur && !init) {
            onBlur(val);
            setInit(true);
        }
    }, [edit]);

    const handleClickOutside = (event) => {
        if (pushDropdown) {
            if (
                ref2.current &&
                !ref2.current.contains(event.target) &&
                ref3.current &&
                !ref3.current.contains(event.target)
            ) {
                setShowOptions(false);
                setEdit(false);
                setTopOfList(
                    options && options?.length > 0
                        ? options?.length > 100
                            ? options?.slice(0, 100)
                            : options
                        : []
                );
            }
        } else if (ref2.current && !ref2.current.contains(event.target)) {
            setShowOptions(false);
            setEdit(false);
            setTopOfList(
                options && options?.length > 0
                    ? options?.length > 100
                        ? options?.slice(0, 100)
                        : options
                    : []
            );
        } else if (ref4.current && !ref4.current.contains(event.target)) {
            setShowOptions(false);
            setEdit(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    function onScroll(e) {
        if (topOfList.length < options.length && !searchValue) {
            var scrollableContainer = e.target;

            let distanceToBottom =
                scrollableContainer.scrollHeight -
                (scrollableContainer.scrollTop +
                    scrollableContainer.clientHeight);

            if (distanceToBottom <= 50) {
                let nextlength = topOfList.length + 100;
                setTopOfList(options.slice(0, nextlength));
            }
        }
    }

    function handleSearch(userInput) {
        let results = [];
        if (userInput) {
            options?.map((option) => {
                if (
                    typeof option === "string" &&
                    option?.toLowerCase()?.includes(userInput?.toLowerCase())
                ) {
                    results.push(option);
                }
                if (
                    typeof option != "string" &&
                    typeof option?.value === "string" &&
                    option.value
                        ?.toLowerCase()
                        ?.includes(userInput?.toLowerCase())
                ) {
                    results.push(option);
                }
                if (
                    typeof option != "string" &&
                    typeof option?.label === "string" &&
                    option.label
                        ?.toLowerCase()
                        ?.includes(userInput?.toLowerCase())
                ) {
                    results.push(option);
                }
            });
        } else {
            results = options;
        }
        setTopOfList(results ? results.slice(0, 100) : []);
    }

    function handleRemoveOption(item, option) {
        let newValues = val && val?.length > 0 ? [...val] : [];
        let index = newValues.findIndex((i) =>
            typeof i === "string"
                ? i === item || i === item?.value
                : i?.value === item || i?.value === item?.value
        );
        newValues.splice(index, 1);
        setVal(newValues);
        setEdited(true);
        if (onChange) {
            setInit(false);
            onChange(newValues, index, option, true);
        }
    }

    const defaultValue = useRef(null);

    function getBackColor() {
        // if (
        //   (val?.value && value?.value) ||
        //   val?.value === false ||
        //   value?.value === false ||
        //   val?.value === true ||
        //   value?.value === true
        // ) {
        //   if (
        //     val?.value != value?.value ||
        //     (val?.value === false && value?.value === true) ||
        //     (val?.value === true && value?.value === false)
        //   ) {
        //     return "#F2C85C";
        //   }
        // }
        // if (
        //   !val?.value &&
        //   !value?.value &&
        //   val?.value != false &&
        //   value?.value != false &&
        //   val?.value != true &&
        //   value?.value != true
        // ) {
        //   if (
        //     val != value ||
        //     (val === false && value === true) ||
        //     (val === true && value === false)
        //   ) {
        //     if (typeof val === "object" && val?.length > 0) {
        //       if (val.length != value.length) {
        //         return "#F2C85C";
        //       } else {
        //         for (let i = 0; i < val.length; i++) {
        //           if (val[i]?.value && value[i]?.value) {
        //             if (val[i]?.value != value[i]?.value) {
        //               return "#F2C85C";
        //             }
        //           }
        //           if (!val[i]?.value && !value[i]?.value) {
        //             if (val[i] != value[i]) {
        //               return "#F2C85C";
        //             }
        //           }
        //         }
        //       }
        //     } else {
        //       return "#F2C85C";
        //     }
        //   }
        // }

        // if (!init) {
        //   return "#ffe7ab51";
        // }

        if (editColor && edited && val != initValue) {
            return "#FFF7E4";
        }

        if (showOptions) {
            return "white";
        }
        if (style?.backgroundColor) {
            return style?.backgroundColor;
        }
        return "";
    }

    function getHeight(num) {
        if (canCreate && searchValue) {
            num += 1;
        }

        if (num <= 6) {
            return num * 40 + "px";
        } else {
            return "";
        }
    }

    const [dateObject, setDateObject] = useState(new SelectedDate());


    function handleChangeDate(newDate) {
        dateObject.timestamp = Date.parse(newDate) / 1000;
        dateObject.day = newDate.getDate();
        dateObject.month = newDate.getMonth() + 1;
        dateObject.year = newDate.getFullYear();
        dateObject.time = time + ":00";
        setVal(newDate);
        const newDateTime = new Date(
            `${findDay(newDate.getDay())}, ${newDate.getDate()} ${findFullMonth(
                newDate.getMonth()
            )} ${newDate.getFullYear()} ${time}`
        );
        dateObject.fullText = newDateTime;
        if (onChange) {
            onChange(dateObject);
        }
    }

    function handleChangeTime(time) {
        setTime(time);
        dateObject.timestamp = Date.parse(val) / 1000;
        dateObject.day = val.getDate();
        dateObject.month = val.getMonth() + 1;
        dateObject.year = val.getFullYear();

        const newDateTime = new Date(
            `${findDay(val.getDay())}, ${val.getDate()} ${findFullMonth(
                val.getMonth()
            )} ${val.getFullYear()} ${time}:00`
        );
        setVal(newDateTime);
        dateObject.fullText = newDateTime;
        dateObject.time = time;
        if (onChange) {
            onChange(dateObject);
        }
    }

    let nullDate = new Date(null);

    const calenderDate = () => {
        if (val) {
            let date = new Date(val);
            if (date.toDateString() === nullDate.toDateString()) {
                return new Date();
            }
            return date;
        } else {
            return new Date();
        }
    };

  const isDateGiven = () => {
    if (val && val?.toDateString) {
      return val?.toDateString() !== nullDate?.toDateString();
    } else {
      return false;
    }
  };



    return (
        <>
            <div
                className={`${styles.container}  ${
                    showOptions || shadow ? styles.shadow : ""
                }`}
                style={{
                    pointerEvents: disable ? "none" : "",
                    ...style,
                    backgroundColor: getBackColor(),
                    border:
                        showOptions && style?.border
                            ? "2px solid transparent"
                            : style?.border,
                    cursor:
                        (select || selectMultiple) && !disable ? "pointer" : "",
                }}
                ref={ref2}
                onClick={() => {
                    anyWhereOptions ? setShowOptions(true) : "";
                }}
            >
                <div className={styles.text}>
                    {label && (
                        <div
                            className={styles.label6}
                            onClick={() => {
                                select || selectMultiple
                                    ? setShowOptions(true)
                                    : "";
                            }}
                        >
                            {label}
                        </div>
                    )}
                    {!select &&
                        !disableInputOnly &&
                        !selectMultiple &&
                        !date && (
                            <input
                                className={styles.input}
                                placeholder={
                                    placeholder ? placeholder : "input"
                                }
                                value={val}
                                style={inputStyle}
                                onChange={(e) => {
                                    setInit(false);
                                    setVal(e.target.value);
                                    setEdited(true);
                                    onChange ? onChange(e.target.value) : "";
                                }}
                                type={type}
                                autoFocus={autoFocus}
                            />
                        )}
                    {(select || disableInputOnly || date) &&
                        !selectMultiple && (
                            <div
                                className={styles.select}
                                onClick={() => {
                                    select || date
                                        ? setShowOptions(!showOptions)
                                        : "";
                                    setEdit(true);
                                }}
                            >
                                {!search && select && (
                                    <div
                                        className={inputClass}
                                        style={inputStyle}
                                        onClick={onInputClick}
                                    >
                                        {val?.label || val?.label === ""
                                            ? val?.label
                                            : val}
                                        {val?.label || val?.label === "" ? (
                                            !val?.label ? (
                                                placeholder
                                            ) : (
                                                ""
                                            )
                                        ) : !val ? (
                                            <div
                                                className={
                                                    styles.section_label6
                                                }
                                                style={{ margin: "0" }}
                                            >
                                                {placeholder}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                )}
                                {search && select && (
                                    <>
                                        <div
                                            ref={defaultValue}
                                            contentEditable
                                            className={styles.searchinput}
                                            placeholder={
                                                placeholder
                                                    ? placeholder
                                                    : "Search..."
                                            }
                                            style={{
                                                padding: "2px 5px",
                                                width: "100%",
                                                ...inputStyle,
                                            }}
                                            value={
                                                val?.label || val?.label === ""
                                                    ? val?.label
                                                    : val
                                            }
                                            onInput={(e) => {
                                                defaultValue.current.textContent =
                                                    e.currentTarget.textContent;
                                                setVal({
                                                    value: e.currentTarget
                                                        .textContent,
                                                    label: e.currentTarget
                                                        .textContent,
                                                });
                                                setInit(false);
                                                setEdited(true);
                                                onChange
                                                    ? onChange(
                                                          {
                                                              value: e
                                                                  .currentTarget
                                                                  .textContent,
                                                              label: e
                                                                  .currentTarget
                                                                  .textContent,
                                                          },
                                                          e.currentTarget
                                                              .textContent
                                                      )
                                                    : "";

                                                handleSearch(
                                                    e.currentTarget.textContent
                                                );
                                            }}
                                            // dangerouslySetInnerHTML={{ __html: defaultValue?.current?.innerHTML }}
                                            suppressContentEditableWarning={
                                                true
                                            }
                                            autoFocus
                                        >
                                            {safeValue}
                                        </div>
                                    </>
                                )}

                                {date && (
                                    <div
                                        className={styles.select}
                                        onClick={() => {
                                            select || date
                                                ? setShowOptions(!showOptions)
                                                : "";
                                            setEdit(true);
                                        }}
                                    >
                                        {" "}
                                        {isDateGiven()
                                            ? `${findDay(
                                                  val?.getDay()
                                              )}, ${findMonth(
                                                  val?.getMonth()
                                              )} ${val?.getDate()} ${val?.getFullYear()} at ${militaryTimeConverter(
                                                  `${time}`
                                              )}`
                                            : placeholder}
                                    </div>
                                )}
                            </div>
                        )}
                    {selectMultiple && (
                        <>
                            {!hideChosen && (
                                <div
                                    className={styles.selectMultiple}
                                    onClick={() => {
                                        selectMultiple
                                            ? setShowOptions(true)
                                            : "";
                                        setEdit(true);
                                    }}
                                    style={{ flexWrap: nowrap ? "nowrap" : "" }}
                                >
                                    {val &&
                                        val?.length > 0 &&
                                        val?.map((item, i) => (
                                            <div
                                                className={styles.chosen}
                                                key={i}
                                                style={chosenStyle}
                                            >
                                                <div
                                                    className={
                                                        styles.chosenText
                                                    }
                                                    style={{
                                                        whiteSpace: nowrap
                                                            ? "nowrap"
                                                            : "",
                                                    }}
                                                >
                                                    {item?.label
                                                        ? item?.label
                                                        : item?.value
                                                        ? item?.value
                                                        : typeof item ===
                                                          "string"
                                                        ? item
                                                        : ""}{" "}
                                                </div>
                                                <i
                                                    className={`bi-x ${styles.remove}`}
                                                    onClick={() =>
                                                        handleRemoveOption(item)
                                                    }
                                                ></i>
                                            </div>
                                        ))}

                                    {/* {val?.label || val?.label === "" ? val?.label : val} */}
                                </div>
                            )}
                            {!noSearch &&
                                (showOptions ||
                                    edit ||
                                    !val ||
                                    val.length === 0 ||
                                    hideChosen) && (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: ".5em",
                                            alignItems: "center",
                                        }}
                                    >
                                        {searchIcon && (
                                            <i
                                                className={`bi-search ${styles.searchIcon}`}
                                            ></i>
                                        )}
                                        <input
                                            className={`${styles.input} ${
                                                nohover && styles.nohover
                                            }`}
                                            value={searchValue}
                                            placeholder={
                                                placeholder
                                                    ? placeholder
                                                    : "Search..."
                                            }
                                            onChange={(e) => {
                                                // setInit(false);
                                                handleSearch(e.target.value);
                                                setSearchValue(e.target.value);
                                            }}
                                            onClick={() => {
                                                selectMultiple
                                                    ? setShowOptions(true)
                                                    : "";
                                                setEdit(true);
                                            }}
                                            autoFocus={autoFocus}
                                        />
                                    </div>
                                )}
                        </>
                    )}
                </div>
                {icon && !disable && (
                    <i
                        className={`${styles.icon} ${icon}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (onIconClick) {
                                onIconClick(e);
                            } else if (select) {
                                setShowOptions(!showOptions);
                            } else if (selectMultiple) {
                                setShowOptions(!showOptions);
                            }
                        }}
                    ></i>
                )}
                {iconReplacement}
                {select && showOptions && !pushDropdown && (
                    <div
                        className={styles.dropdown}
                        onScroll={onScroll}
                        style={{
                            maxHeight: getHeight(topOfList.length),
                        }}
                    >
                        {topOfList &&
                            topOfList.map((option, i) => (
                                <React.Fragment key={i}>
                                    {typeof option === "string" && (
                                        <div
                                            key={i}
                                            className={styles.option}
                                            onClick={(e) => {
                                                if (searchValue) {
                                                    i = options.fin(option);
                                                }
                                                if (search) {
                                                    defaultValue.current.textContent =
                                                        e.currentTarget.textContent;
                                                }

                                                setVal(option);
                                                setInit(false);
                                                setEdited(true);
                                                onChange
                                                    ? onChange(option, option)
                                                    : "";

                                                setShowOptions(false);
                                            }}
                                        >
                                            {option}

                                            <div className={styles.helperText}>
                                                {optionHelperText}
                                            </div>
                                            {val === option &&
                                                !dropdownonly && (
                                                    <div
                                                        className={
                                                            styles.checked
                                                        }
                                                    >
                                                        <i className="bi-check"></i>
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                    {typeof option != "string" && (
                                        <div
                                            key={i}
                                            className={styles.option}
                                            onClick={(e) => {
                                                if (searchValue) {
                                                    i = options.indexOf(option);
                                                }
                                                if (search) {
                                                    defaultValue.current.textContent =
                                                        e.currentTarget.textContent;
                                                }

                                                setVal(option);
                                                setInit(false);
                                                setEdited(true);
                                                onChange
                                                    ? onChange(
                                                          option,
                                                          option?.value
                                                      )
                                                    : "";

                                                setShowOptions(false);
                                            }}
                                        >
                                            {option?.label
                                                ? option?.label
                                                : option?.value}
                                            <div className={styles.helperText}>
                                                {optionHelperText}
                                            </div>
                                            {val?.value === option?.value && (
                                                <div className={styles.checked}>
                                                    <i className="bi-check"></i>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        {canCreate && searchValue && (
                            <div
                                className={styles.option}
                                onClick={() => {
                                    if (search) {
                                        defaultValue.current.textContent =
                                            e.currentTarget.textContent;
                                    }
                                    setVal({
                                        value: searchValue,
                                        label: searchValue,
                                    });
                                    setInit(false);
                                    setEdited(true);
                                    onChange
                                        ? onChange(
                                              {
                                                  value: searchValue,
                                                  label: searchValue,
                                              },
                                              searchValue
                                          )
                                        : "";
                                    setSearchValue("");
                                }}
                            >
                                {createText ? createText : "Create..."}
                            </div>
                        )}
                    </div>
                )}
                {date && showOptions && (
                    <div
                        className={`${styles.dropdown} ${styles.dropdownCalendar}`}
                        onScroll={onScroll}
                        ref={ref4}
                    >
                        <Calendar
                            onChange={disable ? "" : handleChangeDate}
                            value={calenderDate()}
                            // tileDisabled={disabled}
                            className={styles.calendar}
                        />

                        <div className={styles.timePickerContainer}>
                            <div className={styles.section_label6}>Time</div>
                            <TimePicker
                                onChange={disable ? "" : handleChangeTime}
                                value={time}
                                className={styles.timePicker}
                            />
                        </div>
                    </div>
                )}
                {selectMultiple && showOptions && !pushDropdown && (
                    <div
                        className={styles.dropdown}
                        onScroll={onScroll}
                        style={{ maxHeight: getHeight(topOfList.length) }}
                    >
                        {topOfList &&
                            topOfList.map((option, i) => (
                                <React.Fragment key={i}>
                                    {typeof option === "string" && (
                                        <div
                                            key={i}
                                            className={styles.option}
                                            onClick={() => {
                                                if (searchValue) {
                                                    i = options.indexOf(option);
                                                }
                                                if (!dropdownonly) {
                                                    if (
                                                        !val ||
                                                        !val?.find(
                                                            (o) =>
                                                                o === option ||
                                                                o?.value ===
                                                                    option
                                                        )
                                                    ) {
                                                        let value =
                                                            val &&
                                                            val?.length > 0
                                                                ? [...val]
                                                                : [];
                                                        value.push(option);
                                                        setVal(value);
                                                        setInit(false);
                                                        setEdited(true);
                                                        onChange
                                                            ? onChange(
                                                                  value,
                                                                  i,
                                                                  option
                                                              )
                                                            : "";
                                                    } else {
                                                        handleRemoveOption(
                                                            option
                                                        );
                                                    }
                                                    // setShowOptions(false);
                                                    setSearchValue("");
                                                    setTopOfList(
                                                        options &&
                                                            options?.length > 0
                                                            ? options?.length >
                                                              100
                                                                ? options?.slice(
                                                                      0,
                                                                      100
                                                                  )
                                                                : options
                                                            : []
                                                    );
                                                }
                                            }}
                                        >
                                            {option?.label
                                                ? option?.label
                                                : option?.value
                                                ? option?.value
                                                : typeof option === "string"
                                                ? option
                                                : ""}{" "}
                                            <div className={styles.helperText}>
                                                {optionHelperText}
                                            </div>
                                            {val &&
                                                val?.find(
                                                    (o) =>
                                                        o === option ||
                                                        o?.value === option
                                                ) && (
                                                    <div
                                                        className={
                                                            styles.checked
                                                        }
                                                    >
                                                        <i className="bi-check"></i>
                                                    </div>
                                                )}
                                        </div>
                                    )}

                                    {typeof option != "string" && (
                                        <div
                                            key={i}
                                            className={styles.option}
                                            onClick={() => {
                                                if (searchValue) {
                                                    i = options.indexOf(option);
                                                }
                                                if (!dropdownonly) {
                                                    if (
                                                        !val ||
                                                        !val?.find(
                                                            (o) =>
                                                                o ===
                                                                    option?.value ||
                                                                o?.value ===
                                                                    option?.value
                                                        )
                                                    ) {
                                                        let value =
                                                            val &&
                                                            val?.length > 0
                                                                ? [...val]
                                                                : [];
                                                        value.push(option);
                                                        setVal(value);
                                                        setInit(false);
                                                        setEdited(true);

                                                        onChange
                                                            ? onChange(value, i)
                                                            : "";
                                                    } else {
                                                        handleRemoveOption(
                                                            option
                                                        );
                                                    }
                                                    // setShowOptions(false);
                                                    setSearchValue("");
                                                    setTopOfList(
                                                        options &&
                                                            options?.length > 0
                                                            ? options?.length >
                                                              100
                                                                ? options?.slice(
                                                                      0,
                                                                      100
                                                                  )
                                                                : options
                                                            : []
                                                    );
                                                } else {
                                                    onChange
                                                        ? onChange(
                                                              value,
                                                              i,
                                                              option
                                                          )
                                                        : "";
                                                    setShowOptions(false);
                                                    setEdit(false);
                                                    setTopOfList(
                                                        options &&
                                                            options?.length > 0
                                                            ? options?.length >
                                                              100
                                                                ? options?.slice(
                                                                      0,
                                                                      100
                                                                  )
                                                                : options
                                                            : []
                                                    );
                                                }
                                            }}
                                        >
                                            {option?.label
                                                ? option?.label
                                                : option?.value
                                                ? option?.value
                                                : typeof option === "string"
                                                ? option
                                                : ""}{" "}
                                            <div className={styles.helperText}>
                                                {optionHelperText}
                                            </div>
                                            {val?.find(
                                                (o) =>
                                                    o === option?.value ||
                                                    o?.value === option?.value
                                            ) && (
                                                <div className={styles.checked}>
                                                    <i className="bi-check"></i>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        {canCreate && searchValue && (
                            <div
                                className={styles.option}
                                onClick={() => {
                                    setVal({
                                        value: searchValue,
                                        label: searchValue,
                                    });
                                    setInit(false);
                                    setEdited(true);
                                    onChange
                                        ? onChange(
                                              {
                                                  value: searchValue,
                                                  label: searchValue,
                                              },
                                              searchValue
                                          )
                                        : "";
                                    setSearchValue("");
                                }}
                            >
                                {createText ? createText : "Create..."}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {select && showOptions && pushDropdown && (
                <div
                    className={styles.pushdropdown}
                    onScroll={onScroll}
                    style={{
                        minHeight: getHeight(topOfList.length),
                    }}
                    ref={ref3}
                >
                    {topOfList &&
                        topOfList.map((option, i) => (
                            <React.Fragment key={i}>
                                {typeof option === "string" && (
                                    <div
                                        key={i}
                                        className={styles.option}
                                        onClick={(e) => {
                                            if (searchValue) {
                                                i = options.indexOf(option);
                                            }
                                            if (search) {
                                                defaultValue.current.textContent =
                                                    e.currentTarget.textContent;
                                            }

                                            setVal(option);
                                            setInit(false);
                                            setEdited(true);
                                            onChange
                                                ? onChange(option, option)
                                                : "";

                                            setShowOptions(false);
                                        }}
                                    >
                                        {option}

                                        <div className={styles.helperText}>
                                            {optionHelperText}
                                        </div>
                                        {val === option && (
                                            <div className={styles.checked}>
                                                <i className="bi-check"></i>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {typeof option != "string" && (
                                    <div
                                        key={i}
                                        className={styles.option}
                                        onClick={(e) => {
                                            if (searchValue) {
                                                i = options.indexOf(option);
                                            }
                                            if (search) {
                                                defaultValue.current.textContent =
                                                    e.currentTarget.textContent;
                                            }

                                            setVal(option);
                                            setInit(false);
                                            setEdited(true);
                                            onChange
                                                ? onChange(
                                                      option,
                                                      option?.value
                                                  )
                                                : "";

                                            setShowOptions(false);
                                        }}
                                    >
                                        {option?.label
                                            ? option?.label
                                            : option?.value}
                                        <div className={styles.helperText}>
                                            {optionHelperText}
                                        </div>
                                        {val?.value === option?.value && (
                                            <div className={styles.checked}>
                                                <i className="bi-check"></i>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    {canCreate && searchValue && (
                        <div
                            className={styles.option}
                            onClick={() => {
                                if (search) {
                                    defaultValue.current.textContent =
                                        e.currentTarget.textContent;
                                }
                                setVal({
                                    value: searchValue,
                                    label: searchValue,
                                });
                                setInit(false);
                                setEdited(true);
                                onChange
                                    ? onChange(
                                          {
                                              value: searchValue,
                                              label: searchValue,
                                          },
                                          searchValue
                                      )
                                    : "";
                                setSearchValue("");
                            }}
                        >
                            {createText ? createText : "Create..."}
                        </div>
                    )}
                </div>
            )}
            {selectMultiple && showOptions && pushDropdown && (
                <div
                    className={styles.pushdropdown}
                    onScroll={onScroll}
                    style={{
                        maxHeight: getHeight(topOfList.length),
                        marginTop: "-10px",
                        position: "inherit",
                    }}
                    ref={ref3}
                >
                    {topOfList &&
                        topOfList.map((option, i) => (
                            <React.Fragment key={i}>
                                {typeof option === "string" && (
                                    <div
                                        key={i}
                                        className={styles.option}
                                        onClick={() => {
                                            if (searchValue) {
                                                i = options.indexOf(option);
                                            }
                                            if (!dropdownonly) {
                                                if (
                                                    !val ||
                                                    !val?.find(
                                                        (o) =>
                                                            o === option ||
                                                            o?.value === option
                                                    )
                                                ) {
                                                    let value =
                                                        val && val?.length > 0
                                                            ? [...val]
                                                            : [];
                                                    value.push(option);
                                                    setVal(value);
                                                    setInit(false);
                                                    setEdited(true);
                                                    onChange
                                                        ? onChange(value, i)
                                                        : "";
                                                } else {
                                                    handleRemoveOption(option);
                                                }
                                                // setShowOptions(false);
                                                setSearchValue("");
                                                setTopOfList(
                                                    options &&
                                                        options?.length > 0
                                                        ? options?.length > 100
                                                            ? options?.slice(
                                                                  0,
                                                                  100
                                                              )
                                                            : options
                                                        : []
                                                );
                                            } else {
                                                onChange
                                                    ? onChange(value, i, option)
                                                    : "";
                                                setShowOptions(false);
                                                setEdit(false);
                                                setTopOfList(
                                                    options &&
                                                        options?.length > 0
                                                        ? options?.length > 100
                                                            ? options?.slice(
                                                                  0,
                                                                  100
                                                              )
                                                            : options
                                                        : []
                                                );
                                            }
                                        }}
                                    >
                                        {option?.label
                                            ? option?.label
                                            : option?.value
                                            ? option?.value
                                            : typeof option === "string"
                                            ? option
                                            : ""}{" "}
                                        <div className={styles.helperText}>
                                            {optionHelperText}
                                        </div>
                                        {val &&
                                            val?.find(
                                                (o) =>
                                                    o === option ||
                                                    o?.value === option
                                            ) && (
                                                <div className={styles.checked}>
                                                    <i className="bi-check"></i>
                                                </div>
                                            )}
                                    </div>
                                )}

                                {typeof option != "string" && (
                                    <div
                                        key={i}
                                        className={styles.option}
                                        onClick={() => {
                                            if (searchValue) {
                                                i = options.indexOf(option);
                                            }
                                            if (!dropdownonly) {
                                                if (
                                                    !val ||
                                                    !val?.find(
                                                        (o) =>
                                                            o ===
                                                                option?.value ||
                                                            o?.value ===
                                                                option?.value
                                                    )
                                                ) {
                                                    let value =
                                                        val && val?.length > 0
                                                            ? [...val]
                                                            : [];
                                                    value.push(option);
                                                    setVal(value);
                                                    setInit(false);
                                                    setEdited(true);
                                                    onChange
                                                        ? onChange(value, i)
                                                        : "";
                                                } else {
                                                    handleRemoveOption(option);
                                                }
                                                // setShowOptions(false);
                                                setSearchValue("");
                                                setTopOfList(
                                                    options &&
                                                        options?.length > 0
                                                        ? options?.length > 100
                                                            ? options?.slice(
                                                                  0,
                                                                  100
                                                              )
                                                            : options
                                                        : []
                                                );
                                            } else {
                                                onChange
                                                    ? onChange(value, i, option)
                                                    : "";
                                                setShowOptions(false);
                                                setEdit(false);
                                                setTopOfList(
                                                    options &&
                                                        options?.length > 0
                                                        ? options?.length > 100
                                                            ? options?.slice(
                                                                  0,
                                                                  100
                                                              )
                                                            : options
                                                        : []
                                                );
                                            }
                                        }}
                                    >
                                        {option?.label
                                            ? option?.label
                                            : option?.value
                                            ? option?.value
                                            : typeof option === "string"
                                            ? option
                                            : ""}{" "}
                                        <div className={styles.helperText}>
                                            {optionHelperText}
                                        </div>
                                        {val?.find(
                                            (o) =>
                                                o === option?.value ||
                                                o?.value === option?.value
                                        ) && (
                                            <div className={styles.checked}>
                                                <i className="bi-check"></i>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    {canCreate && searchValue && (
                        <div
                            className={styles.option}
                            onClick={() => {
                                let value =
                                    val && val?.length > 0 ? [...val] : [];
                                value.push({
                                    value: searchValue,
                                    label: searchValue,
                                });
                                setVal(value);
                                setInit(false);
                                setEdited(true);
                                onChange
                                    ? onChange(value, value.length - 1)
                                    : "";
                                setSearchValue("");
                            }}
                        >
                            {createText ? createText : "Create..."}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default CombinedInput;
