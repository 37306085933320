import styles from "../SettingsAccordion.module.scss";
import { useState } from "react";

import { SelectField } from "components/inputs";
import { combineFilters } from "../../Visualization";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { QData } from "./SurveyData/QData";
import { TableType } from "../../QuestionChart";
import FlexRow from "components/layouts/FlexRow/FlexRow";

const dataMethods = [
    { value: "question", label: "Get data from survey questions" },
    { value: "survey", label: "Get data from survey stats" },
    // { value: "message", label: "Get data from survey messages" },
    // { value: "manual", label: "Manually add data" },
];

export const DataSettings = ({
    viz,
    updateViz,
    title,
    visible,
    externalFilter,
    hasData,
    setVisible,
    newChart,
    lockData,
}) => {
    function handleBorderRadiusChange(val) {
        changeSettingsField("borderRadius", val);
    }

    const [dataMethod, setDataMethod] = useState(
        viz.dataMethod
            ? dataMethods.find((m) => m.value === viz.dataMethod)
            : { value: "question", label: "Get data from survey questions" }
    );

    function handleBarWidthChange(val) {
        val = val / 10;
        changeSettingsField("barPercentage", val);
    }

    function handleArcWidthChange(val) {
        // if (val === 10) {
        //   val = 0;
        // } else {
        //   val = (val - 10) * -1;
        //   val *= 10;
        // }

        val *= 10;
        changeSettingsField("cutout", val);
    }

    function getCutoutVal() {
        // if (viz.designSettings.cutout === 0) {
        //   return 10;
        // }
        // if (viz.designSettings.cutout) {
        //   return 10 - viz.designSettings.cutout / 10;
        // }
        // return 5;
        return (viz.designSettings.cutout ? viz.designSettings.cutout : 0) / 10;
    }

    return (
        <>
            <div
                key={viz.id}
                className={`${styles.header} ${
                    visible ? styles.headervisible : ""
                }`}
                onClick={!visible ? setVisible : undefined}
            >
                <FlexRow
                    onClick={visible ? setVisible : undefined}
                    style={{ cursor: "pointer" }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1em",
                        }}
                    >
                        {title}{" "}
                        <i
                            style={{ fontSize: "1.2em" }}
                            className="bi-receipt-cutoff"
                        ></i>
                    </div>

                    <span className={styles.accordionicon}>
                        <i className="bi bi-caret-left-fill"></i>
                    </span>
                </FlexRow>
                {visible && (
                    <>
                        <div className={styles.dataHeader}>
                            <div style={{ fontSize: ".8em" }}>
                                <SelectField
                                    onChange={(data) => changeMethod(data)}
                                    options={dataMethods}
                                    value={dataMethod}
                                    placeholder="Choose how to get your data"
                                    height={28}
                                    disable={viz.type === TableType}
                                ></SelectField>
                            </div>
                        </div>
                        {dataMethod.value === "question" && (
                            <>
                                {newChart &&
                                    !combinedQs(viz).length &&
                                    noEmptyQTagSelections() && (
                                        <div className={styles.switchType}>
                                            <div
                                                className={
                                                    styles.selectContainer
                                                }
                                            >
                                                <CombinedInput
                                                    value={getCurrType()}
                                                    options={typeVals}
                                                    style={{
                                                        backgroundColor:
                                                            "#ffffff",
                                                    }}
                                                    hideChosen
                                                    select
                                                    label={"Type"}
                                                    icon={
                                                        "bi bi-chevron-compact-down"
                                                    }
                                                    onChange={onChangeType}
                                                />
                                            </div>
                                        </div>
                                    )}

                                <div className={styles.dataSettings}>
                                    {!lockData && (
                                        <QData // clipboard-data      journal-text
                                            viz={viz}
                                            updateViz={updateViz}
                                            newChart={newChart}
                                        ></QData>
                                    )}

                                    <HorizontalBar
                                        width={3}
                                        height={"100%"}
                                        rotate
                                    />

                                    {hasData && (
                                        <AdvancedSettings
                                            viz={viz}
                                            updateViz={updateViz}
                                            filters={combineFilters(
                                                viz,
                                                externalFilter
                                            )}
                                            changeSettingsField={
                                                changeSettingsField
                                            }
                                        ></AdvancedSettings>
                                    )}
                                </div>
                                <HorizontalBar
                                    width={"96%"}
                                    height={2}
                                    style={{ margin: "0em" }}
                                />
                            </>
                        )}

                        {dataMethod.value === "survey" && (
                            <>
                                <div className={styles.dataSettings}>
                                    {!lockData && (
                                        <SurveyData // clipboard-data      journal-text
                                            viz={viz}
                                            updateViz={updateViz}
                                            method={dataMethod.value}
                                            newChart={newChart}
                                        ></SurveyData>
                                    )}

                                    <HorizontalBar
                                        width={3}
                                        height={"100%"}
                                        rotate
                                    />
                                    {hasData && (
                                        <AdvancedStatSettings
                                            viz={viz}
                                            updateViz={updateViz}
                                            changeSettingsField={
                                                changeSettingsField
                                            }
                                            projects={projects}
                                        ></AdvancedStatSettings>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
