import React, { useEffect, useState, useRef } from "react";
import styles from "./ReportEditor.module.scss";
import { useParams } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import {
  useDeleteReportDraft,
  useFetchReport,
  useFetchReportDraft,
  useFetchUserReportRole,
  usePublishDraft,
} from "api/resources/organization/reports";
import Report from "./Report";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { REPORTDRAFTS } from "api/resources/subscription/subscribe";
import { useSubscription } from "@apollo/client";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { BackToTable } from "pages/dashboard/PickDash";

export const CustomReport = ({ collapse, basic, user }) => {
  const { reportid } = useParams();

  const [editing, setEditing] = useState(
    localStorage.getItem("analysis-editing-" + reportid) ? true : false
  );

  return (
    <ReportEditor
      reportid={reportid}
      editing={editing}
      setEditing={setEditing}
      collapse={collapse}
      basic={basic}
      user={user}
    />
  );
};

export const DashReport = ({
  user,
  collapse,
  dashboard,
  canEdit,
  multiUserDash,
  setShowTable,
  setShowDashes,
}) => {
  const [editing, setEditing] = useState(
    localStorage.getItem("analysis-editing-" + dashboard.reportId)
      ? true
      : false
  );

  return (
    <div className={styles.dashReport}>
      <div
        className={`${styles.headerContainer} ${
          editing ? styles.solidHeader : ""
        }`}
      >
        <div className={styles.welcome}>{`Welcome, ${
          user.prefix ? user.prefix : ""
        } ${user.firstName} ${user.lastName}`}</div>
        <div className={styles.topButtons}>
          {canEdit && !editing && (
            <Button
              onClick={() => setEditing(true)}
              style={{
                display: "flex",
                gap: "7px",
              }}
              shadow
            >
              <Icon iconName={"pencil-square"}></Icon>
              <div>Edit Dashboard</div>
            </Button>
          )}
          <Button
            onClick={() => setShowDashes(true)}
            style={{
              display: "flex",
              gap: "7px",
            }}
            shadow
          >
            <Icon iconName={"arrow-right-square"} info></Icon>
            <div>Change Dashboard</div>
          </Button>
        </div>
      </div>

      {setShowTable && !editing && (
        <div className={styles.pageContent}>
          <div className={styles.pickDifferentUser}>
            <BackToTable onClick={() => setShowTable(true)} />{" "}
          </div>
        </div>
      )}
      <ReportEditor
        reportid={dashboard.reportId}
        editing={editing}
        setEditing={setEditing}
        collapse={collapse}
        dash
        user={user}
      />
    </div>
  );
};

export function ReportEditor({
  reportid,
  editing,
  setEditing,
  collapse,
  basic,
  dash,
  user,
}) {
  const fetchCustomFields = useFetchCurrOrgsFields();
  const editRef = useRef();

  function onEditChart(info) {
    editRef.current = info;
    setEditing(true);
  }

  useEffect(() => {
    if (editing) {
      localStorage.setItem("analysis-editing-" + reportid, true);
      collapse("collapsed");
      localStorage.setItem("sidemenustate", "collapsed");
    } else {
      localStorage.removeItem("analysis-editing-" + reportid);
      collapse("expanded");
      localStorage.setItem("sidemenustate", "expanded");
    }
  }, [editing]);

  return (
    <div className={styles.customReport}>
      {reportid && fetchCustomFields.isSuccess && (
        <>
          {!editing && (
            <ReportContainer
              reportid={reportid}
              custom_fields={fetchCustomFields.data.getCurrOrgFields}
              editing={editing}
              setEditing={setEditing}
              basic={basic}
              onEditChart={onEditChart}
              dash={dash}
            ></ReportContainer>
          )}
          {editing && (
            <ReportContainerDraft
              reportid={reportid}
              custom_fields={fetchCustomFields.data.getCurrOrgFields}
              editing={editing}
              setEditing={setEditing}
              basic={basic}
              editRef={editRef}
              dash={dash}
              user={user}
            ></ReportContainerDraft>
          )}
        </>
      )}
    </div>
  );
}

const ReportContainer = ({
  reportid,
  custom_fields,
  editing,
  setEditing,
  basic,
  onEditChart,
  dash,
}) => {
  const fetchReport = useFetchReport(reportid, checkDraft);
  const role = useFetchUserReportRole(reportid);

  function checkDraft(data) {
    if (data.report.draft) {
      setEditing(true);
    }
  }

  return (
    <>
      {fetchReport.isLoading && <Loading />}
      {fetchReport.isError && <div>error...</div>}
      {fetchReport.isSuccess && role.isSuccess && (
        <Report
          report={fetchReport.data.report}
          custom_fields={custom_fields}
          refetchReport={() => fetchReport.refetch()}
          canEdit={role?.data?.canEdit}
          editing={editing}
          setEditing={setEditing}
          basic={basic}
          onEditChart={onEditChart}
          dash={dash}
          widescreen={dash}
        ></Report>
      )}
    </>
  );
};

const ReportContainerDraft = ({
  reportid,
  custom_fields,
  editing,
  setEditing,
  basic,
  editRef,
  dash,
  user,
}) => {
  const fetchReport = useFetchReportDraft(reportid);
  const publishDraft = usePublishDraft();

  const [task, setTask] = useState();
  const { data, loading } = useSubscription(REPORTDRAFTS, {
    variables: { userId: user?.id, reportId: reportid },
  });

  useEffect(() => {
    if (data && data.task && data.task?.itemId === reportid) {
      setTask({
        ...data.task,
      });

      if (data?.task.progress === 100) {
        setTimeout(() => {
          setTask();
        }, 500);
      }
    }
  }, [data, loading]);

  function publish() {
    if (
      window.confirm(
        "Are you sure you'd like to publish your changes to the live report?"
      )
    ) {
      publishDraft.mutate(
        {
          id: reportid,
        },
        {
          onSuccess: () => {
            setEditing(false);
          },
          onError: (data) => {
            console.log("error");
            setTask();
          },
        }
      );
    }
  }

  const discard = useDeleteReportDraft();
  function deleteDraft(report) {
    discard.mutate(
      {
        id: report?.id,
      },
      {
        onSuccess: (data) => {
          if (!report.draftId) {
            navigate("/reports/home");
          }
          setEditing(false);
        },
      },
      {
        onError: (data) => {
          console.log("ERROR Removing Draft");
          setTask();
        },
      }
    );
  }

  return (
    <>
      {!task && (
        <>
          {fetchReport.isLoading && <Loading />}
          {fetchReport.isError && <div>error...</div>}
          {fetchReport.isSuccess && (
            <Report
              report={fetchReport.data.report}
              custom_fields={custom_fields}
              refetchReport={() => fetchReport.refetch()}
              canEdit
              editing={editing}
              setEditing={setEditing}
              basic={basic}
              editRef={editRef}
              publish={publish}
              deleteDraft={deleteDraft}
              dash={dash}
              widescreen={dash}
            ></Report>
          )}
        </>
      )}

      {task && (
        <div className={styles.loadingDock}>
          <div className={styles.taskContainer}>
            <div className={styles.title}>
              {task.title}
              <Player
                autoplay
                loop
                src={require(`assets/animations/${
                  task.type === "publishing" ? "uploading" : "Gears"
                }.json`)}
                style={{ height: "120px" }}
              />
            </div>
            <div className={styles.progress}>
              <div className={styles.fillProgress}>
                <div
                  className={styles.progressBar}
                  style={{
                    width: `${task.progress}%`,
                  }}
                ></div>
              </div>
              <div className={styles.progressReport}>{task.progress + "%"}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
