import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import styles from "./Tiles.module.scss";
import { useFetchReportInfo } from "api/resources/organization/reports";
import { Loading } from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { trimDate } from "assets/functions/DateFunctions";

export default function ReportTile({ widget, inSettings }) {
  const reportInfo = useFetchReportInfo(widget.settings?.report?.id);

  return (
    <>
      {reportInfo.isLoading && <Loading />}
      {reportInfo.isSuccess && <Tile report={reportInfo.data.reportInfo} />}
    </>
  );

  function Tile({ report }) {
    let navigate = useNavigate();

    function goTo() {
      window.localStorage.setItem("activepage", 2);
      navigate("/report/" + report.id);
    }

    function checktype(type) {
      if (report?.organizationId) {
        return styles.isclosed;
      }
      if (report?.userId && !report.organizationId) {
        return styles.isResults;
      }
      return styles.isReport;
    }

    return (
      <div
        className={styles.tile}
        onClick={inSettings ? undefined : goTo}
        style={inSettings ? { cursor: "default" } : undefined}
      >
        <div className={styles.reportIcon}>
          {/* {!report?.icon && (
            <span
              className={`bi bi-${icons2[Math.floor(Math.random() * 22)]}`}
            ></span>
          )} */}
          {report?.icon && <span className={`bi bi-${report?.icon}`}></span>}
        </div>
        <div className={styles.reportHeader}>
          <div className={styles.startedText}>
            Created: {trimDate(report?.createdAt)}
          </div>{" "}
          <div className={styles.icons}>
            {/* <div
              className={starred ? styles.starFill : styles.star}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateFavs(!starred);
              }}
            >
              {!starred && <i className="bi bi-star"></i>}
              {starred && <i className="bi bi-star-fill"></i>}
            </div> */}
            {/* <span className={`${styles.status} ${checktype(report?.type)}`}>
           
              {report.type}
            </span> */}
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{report?.name}</div>
          <div className={styles.description}>{report?.content}</div>
        </div>
        <div className={styles.bottomContainer}>
          <div>
            {/* {report.organizationId && (
              <>
                <span>Associated Survey</span>
                <div>{report?.project ? report?.project?.name : "None"}</div>
              </>
            )} */}
            {/* {!report.organizationId &&
              role.isSuccess &&
              users.isSuccess &&
              checkRole(role.data.canEdit) && (
                <>
                  {users.data.response.userCount > 0 && (
                    <>
                      <span>Shared With</span>

                      {users.data.response.userCount > 0 && (
                        <div className={styles.users}>
                          {users.data?.response?.users.map((user, i) => (
                            <ImageContainer
                              key={i}
                              src={user?.image?.imageURL}
                              size={3}
                              margin={i != 0 ? -20 : 0}
                            />
                          ))}{" "}
                          {users.data.response.userCount > 3 && (
                            <span>
                              + {users.data.response.userCount - 3} other
                              {users.data.response.userCount - 3 > 1 ? "s" : ""}
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )} */}
          </div>
          <div>
            <Stat
              title={report?.numPages > 1 ? "Pages" : "Page"}
              value={report?.numPages > 1 ? report?.numPages : 1}
              small
            />
            {/* <Stat
              title={
                report?.numVizualizations > 1
                  ? "Visualizations"
                  : "Visualization"
              }
              value={
                report?.numVizualizations > 1 ? report?.numVizualizations : 1
              }
              small
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
